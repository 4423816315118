import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { SmoothCornersIcon } from './smooth-corners-icon';
import { AppIcon } from './app-icon';

export type AppIconWithContainerProps = {
  icon: string | null | undefined;
  backgroundColor?: string;
  fill?: string;
  linearGradient?: string;
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  opaque?: boolean;
};

export function AppIconWithContainer({
  icon,
  backgroundColor = 'black',
  fill = '#fff',
  size = 'small',
  opaque,
  linearGradient = 'linear-gradient(180deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0.7) 100%)',
  ...forwardingProps
}: AppIconWithContainerProps) {
  const { iconSize, areaSize } = {
    small: { iconSize: 18, areaSize: 32 },
    medium: { iconSize: 20, areaSize: 36 },
    large: { iconSize: 28, areaSize: 48 },
    extraLarge: { iconSize: 35, areaSize: 60 }
  }[size];

  return (
    <SmoothCornersIcon
      size={areaSize}
      color={
        opaque
          ? tinycolor(backgroundColor).setAlpha(0.15).toString()
          : backgroundColor
      }
      {...forwardingProps}
    >
      <StyledAppIcon
        icon={icon}
        size={iconSize}
        fill={fill}
        linearGradient={linearGradient}
      />
    </SmoothCornersIcon>
  );
}

const StyledAppIcon = styled(AppIcon)`
  position: relative;
  z-index: 2;
  pointer-events: none;
`;
