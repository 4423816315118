import useCopyClipboard from 'react-use-clipboard';
import styled, { useTheme } from 'styled-components';
import { Popover, ThemeColor } from '@elfsight-universe/ui-common';

export interface CouponProps {
  coupon: string;
  color?: ThemeColor;
  borderColor?: ThemeColor;
}

export function Coupon({
  coupon,
  color = 'promoTransparent',
  borderColor = 'promo'
}: CouponProps) {
  const [isCopied, copyTextToClipboard] = useCopyClipboard(coupon || '', {
    successDuration: 1000
  });
  const { zIndex } = useTheme();

  return (
    <Popover
      isOpen={isCopied}
      positions={['top']}
      containerStyle={{ zIndex: zIndex.coupon.toString() }}
      padding={4}
      content={<Copied>Copied</Copied>}
    >
      <Container
        _color={color}
        _borderColor={borderColor}
        onClick={copyTextToClipboard}
      >
        {coupon}
      </Container>
    </Popover>
  );
}

const Container = styled.div<{
  _color: ThemeColor;
  _borderColor: ThemeColor;
}>`
  ${({ theme }) => theme.font.title3};
  background: ${({ theme, _color }) => theme.colors[_color]};
  border: 1px dashed ${({ theme, _borderColor }) => theme.colors[_borderColor]};
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 0 16px;
`;

const Copied = styled.div`
  ${({ theme }) => theme.font.caption};
  padding: 6px 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;
