import { FeatureResolver } from '../../feature-resolver';
import { CollaboratorsLimitTooltip } from './collaborators-limit-tooltip';

export const collaboratorsLimitFeatureResolver: FeatureResolver = (
  { collaboratorsLimit },
  pricingTableColorTheme
) => {
  const collaboratorsWordSentence =
    collaboratorsLimit > 1 ? 'collaborators' : 'collaborator';
  const text =
    collaboratorsLimit > 0
      ? `${collaboratorsLimit} ${collaboratorsWordSentence}`
      : `Collaborators`;

  return {
    text: (
      <CollaboratorsLimitTooltip
        pricingTableColorTheme={pricingTableColorTheme}
      >
        <span>{text}</span>
      </CollaboratorsLimitTooltip>
    ),
    excluded: collaboratorsLimit === 0
  };
};
