import { CSSProperties, PropsWithChildren, ReactNode, useRef } from 'react';
import BaseModal, { Props as BaseModalProps } from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { Close20 } from '@elfsight/icons';
import { useTheme } from '../../theme';
import { IconButton } from '../buttons';
import { useWindowHeight } from '../../utils';
import { Loader } from '../loader';

BaseModal.setAppElement('#__next');

const MODAL_OVERLAY_CLASS_NAME = '.ReactModal__Overlay';
const Y_OFFSET = 40;

type TitleAlign = 'left' | 'center';

export type ModalProps = PropsWithChildren<BaseModalProps> & {
  title?: ReactNode;
  actions?: ReactNode;
  maxWidth?: number;
  withClose?: boolean;
  closeColor?: string;
  padding?: [number, number];
  zIndex?: number;
  isLoading?: boolean;
  containerComponent?: typeof DefaultContainer;
  titleAlign?: TitleAlign;
};

export function Modal({
  children,
  title,
  actions,
  maxWidth = 400,
  isOpen,
  withClose,
  closeColor,
  padding = [20, 24],
  onRequestClose,
  zIndex,
  isLoading,
  style,
  titleAlign = 'center',
  containerComponent: Container = DefaultContainer,
  ...forwardingProps
}: ModalProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { colors, zIndex: themeZIndex } = useTheme();
  const windowHeight = useWindowHeight();

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      style={{
        ...style,
        content: {
          ...modalContentStyle,
          ...style?.content
        },
        overlay: {
          zIndex: zIndex || themeZIndex.modalDefault,
          background: colors.gray50,
          ...style?.overlay
        }
      }}
      {...forwardingProps}
    >
      <CSSTransition
        nodeRef={contentRef}
        classNames="slide-in"
        timeout={0}
        in
        appear
      >
        <ContainerWrapper
          ref={contentRef}
          _maxWidth={maxWidth}
          _maxHeight={windowHeight}
          onClick={(e) => e.stopPropagation()}
        >
          <Container _padding={padding}>
            {withClose && (
              <CloseButton
                large
                rounded
                icon={Close20}
                onClick={onRequestClose}
                customColor={closeColor}
              />
            )}

            {isLoading && <Loader />}

            {title && <Title textAlign={titleAlign}>{title}</Title>}
            {children}
            {actions && <Actions>{actions}</Actions>}
          </Container>
        </ContainerWrapper>
      </CSSTransition>
    </BaseModal>
  );
}

const modalContentStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  boxSizing: 'border-box',
  background: 'transparent',
  pointerEvents: 'none',
  inset: 0,
  border: 'none'
};

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: ${({ theme }) => theme.zIndex.modalDefault};
`;

const DefaultContainer = styled.div<{
  _padding?: [number, number];
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: ${({ _padding = [20, 24] }) => `${_padding[0]}px ${_padding[1]}px`};
`;

const ContainerWrapper = styled.div<{
  _maxWidth?: number;
  _maxHeight?: number;
}>`
  position: relative;
  box-sizing: border-box;
  pointer-events: all;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  ${({ theme }) => theme.font.text};
  max-height: ${({ _maxHeight }) => {
    const finalMaxHeight = _maxHeight ? `${_maxHeight}px` : '100vh';
    return `calc(${finalMaxHeight} - ${Y_OFFSET}px)`;
  }};

  width: 100%;
  ${({ _maxWidth }) =>
    _maxWidth &&
    css`
      max-width: ${_maxWidth}px;
    `}

  transform: translateY(24px);
  opacity: 0;
  transition: all 200ms ease-in-out;

  ${MODAL_OVERLAY_CLASS_NAME}--after-open & {
    transform: translateY(0);
    opacity: 1;
  }

  ${MODAL_OVERLAY_CLASS_NAME}--before-close & {
    transform: translateY(24px);
    opacity: 0;
  }
`;

const Title = styled.div<{ textAlign: TitleAlign }>`
  ${({ theme }) => theme.font.title2};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: 8px;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 12px;

  @media (max-width: 400px) {
    flex-direction: column-reverse;
  }
`;
