import { Exclude, Expose, Type } from 'class-transformer';

export const GET_ALL_BADGES_PATH = 'widget/get-all-badges';

@Exclude()
export class GetAllBadgesResponse {
  @Expose()
  @Type(() => GetAllBadgesResponseBadge)
  payload: GetAllBadgesResponseBadge[];
}

@Exclude()
export class GetAllBadgesResponseBadge {
  @Expose()
  pid: string;

  @Expose()
  title: string;

  @Expose()
  icon: string;

  @Expose()
  alias: string;
}
