import {
  PropsWithChildren,
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react';
import { useRouter } from 'next/router';

interface IHistoryContext {
  history: string[];
  back(fallbackRoute?: string, clear?: boolean): void;
  hasPreviousPage: boolean;
}

const HistoryContext = createContext<IHistoryContext | null>(null);

export function AppHistoryProvider({ children }: PropsWithChildren) {
  const { push, events } = useRouter();
  const [history, setHistory] = useState<string[]>([]);

  const back = (fallbackRoute = '/', clearParams = false) => {
    const previousIndex = history.length - 2;
    const previousHistory = history[previousIndex];

    if (!previousHistory) {
      return push(fallbackRoute);
    }

    setHistory(history.slice(0, previousIndex));

    return push(clearParams ? clearURL(previousHistory) : previousHistory);
  };

  useEffect(() => {
    const handleBeforeHistoryChange = (url: string) => {
      setHistory((previous) => {
        const lastIndex = previous.length - 1;
        const lastURL = previous[lastIndex];

        if (lastURL && isSamePage(lastURL, url)) {
          previous.splice(lastIndex, 1, url);
          return previous;
        }

        return [...previous, url];
      });
    };

    events.on('beforeHistoryChange', handleBeforeHistoryChange);

    return () => {
      events.off('beforeHistoryChange', handleBeforeHistoryChange);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      back,
      history,
      hasPreviousPage: history.length > 1
    }),
    [back, history]
  );

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
}

const isSamePage = (urlA: string, urlB: string) => {
  const regExp = /^(\/[^\/?]*)?(.*)/;
  return urlA.replace(regExp, '$1') === urlB.replace(regExp, '$1');
};
const clearURL = (url: string) => url.replace(/^(\/[^?#]*)/, '$1');

export function useHistory() {
  const context = useContext(HistoryContext);

  if (context === null) {
    throw new Error(
      '`useHistory` must be nested inside an `AppHistoryProvider`.'
    );
  }

  return context;
}
