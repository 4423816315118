import {
  FeatureTooltip,
  FeatureTooltipProps
} from '../../../components/feature-tooltip';

export function ProjectsLimitTooltip({
  children,
  ...forwardingProps
}: Omit<FeatureTooltipProps, 'content'>) {
  return (
    <FeatureTooltip
      content={
        <>
          Projects allow you to group widgets for seamless access and efficient
          client management.
        </>
      }
      {...forwardingProps}
    >
      {children}
    </FeatureTooltip>
  );
}
