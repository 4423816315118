import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  PopoverProps,
  Popover as BasePopover,
  PopoverPosition
} from 'react-tiny-popover';
import styled from 'styled-components';
import { useTheme } from '../../theme';
import { slideInTranslateMixin } from './slide-in-translate-mixin';

export function Popover({
  content,
  children,
  isOpen,
  containerStyle,
  ...forwardingProps
}: PopoverProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { zIndex: themeZIndex } = useTheme();

  return (
    <BasePopover
      isOpen={isOpen}
      containerStyle={{
        zIndex: themeZIndex.popoverDefault.toString(),
        ...containerStyle
      }}
      positions={['bottom', 'top']}
      align="center"
      padding={4}
      boundaryInset={12}
      content={(state) => (
        <CSSTransition
          nodeRef={contentRef}
          classNames="slide-in"
          timeout={0}
          in
          appear
        >
          <Content ref={contentRef} _position={state.position}>
            {typeof content === 'function' ? content(state) : content}
          </Content>
        </CSSTransition>
      )}
      {...forwardingProps}
    >
      {children}
    </BasePopover>
  );
}

const Content = styled.div<{ _position?: PopoverPosition }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);

  ${slideInTranslateMixin(8)}
`;
