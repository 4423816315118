import {
  IsNotEmpty,
  IsString,
  IsEmail,
  IsUUID,
  MaxLength,
  IsBoolean,
  IsOptional
} from 'class-validator';
import { Exclude, Expose } from 'class-transformer';

export const CREATE_INSTALLATION_REQUEST_APPEAL_PATH =
  'appeal/create-installation-request-appeal';

export class CreateInstallationRequestAppealRequest {
  @IsNotEmpty({ message: 'Platform is required.' })
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  platform: string;

  @IsNotEmpty({ message: 'Website URL is required.' })
  @IsString()
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  where: string;

  @IsNotEmpty({ message: 'Widget Placement Description is required.' })
  @IsString()
  @MaxLength(4096, { message: 'Must be shorter than 4096 characters.' })
  details: string;

  @IsString()
  @MaxLength(4096, { message: 'Must be shorter than 4096 characters.' })
  @IsOptional()
  additionalInfo?: string;

  @IsString()
  @MaxLength(4096, { message: 'Must be shorter than 4096 characters.' })
  @IsOptional()
  accessDetails?: string;

  @IsBoolean()
  @IsOptional()
  collaboratorAccess?: boolean;

  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail(undefined, { message: 'Email is invalid.' })
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  contactEmail: string;

  @IsUUID()
  widgetPid: string;

  @IsString()
  appAlias: string;
}

@Exclude()
export class CreateInstallationRequestAppealResponse {
  @Expose()
  pid: string;
  @Expose()
  createdAt: Date;
}
