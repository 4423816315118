import { useRouter } from 'next/router';
import { Toast } from '@modules/_app/app-toaster/toast';
import ToastInviteCollaboratorIcon from '../assets/toast-invite-collaborator.svg';

type ToastInviteCollaboratorProps = {
  onClose: () => void;
  email: string;
  withLink?: boolean;
};

export function ToastInviteCollaborator({
  email,
  withLink,
  onClose
}: ToastInviteCollaboratorProps) {
  const { push } = useRouter();

  return (
    <Toast
      message={`Invite sent to ${email}`}
      icon={<ToastInviteCollaboratorIcon />}
      actionText="See All Members"
      withAction={withLink}
      onAction={() => push('/members')}
      onClose={onClose}
    />
  );
}
