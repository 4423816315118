import styled from 'styled-components';
import { H1, P, MOBILE } from '@elfsight-universe/ui-common';
import { useIsMobile } from '@modules/_app';
import { TESTIMONIALS_ITEMS } from './constants/testimonials-items';

export function PricingTestimonials() {
  const isMobile = useIsMobile();

  return (
    <Container>
      <>
        <H1 mb={isMobile ? 20 : 40} alignCenter>
          Trusted by customers in all the world
        </H1>

        <ItemList>
          {TESTIMONIALS_ITEMS.map(({ id, caption, description }) => (
            <Item key={id}>
              <Caption>{caption}</Caption>
              <P>{description}</P>
            </Item>
          ))}
        </ItemList>
      </>
    </Container>
  );
}

const Container = styled.div`
  padding-bottom: 48px;
`;

const ItemList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;

  padding: 0;
  margin: 0;

  ${MOBILE} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px 24px;
  }
`;

const Item = styled.li`
  list-style: none;
`;

const Caption = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.black};
`;
