import { forwardRef, MouseEvent, ReactNode, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import styled from 'styled-components';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
  useTransitionStyles
} from '@floating-ui/react';
import { More } from '@elfsight/icons';
import { useTheme } from '@elfsight-universe/ui-common';
import { IconButton } from '../../buttons';
import { ContextMenuContext } from './context-menu-context';
import { createDropdownSlideInAnimation } from '../create-dropdown-slide-in-animation';

const OFFSET = 4;

export type ContextMenuProps = {
  children: ReactNode;
  largeButton?: boolean;
  primaryButton?: boolean;
  disabledButton?: boolean;
  tooltipContent?: ReactNode;
};

export const ContextMenu = forwardRef<HTMLElement, ContextMenuProps>(
  function _ContextMenu(
    {
      children,
      largeButton,
      primaryButton,
      disabledButton = false,
      tooltipContent = 'More Actions',
      ...forwardingProps
    },
    forwardingRef
  ) {
    const [isOpen, setOpen] = useState(false);
    const { zIndex: themeZIndex } = useTheme();

    const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setOpen(!isOpen);
    };

    const nodeId = useFloatingNodeId();
    const { refs, floatingStyles, context } = useFloating({
      nodeId,
      open: isOpen,
      onOpenChange: setOpen,
      middleware: [
        offset(OFFSET),
        shift(),
        flip({
          padding: 8
        })
      ],
      whileElementsMounted: autoUpdate,
      placement: 'bottom-start'
    });
    const { isMounted, styles: transitionStyles } = useTransitionStyles(
      context,
      createDropdownSlideInAnimation()
    );

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
      click,
      dismiss,
      role
    ]);

    return (
      <ContextMenuContext.Provider value={{ isOpen, setOpen }}>
        <>
          <IconButton
            disabled={disabledButton}
            icon={More}
            large={largeButton}
            primary={primaryButton}
            active={isOpen}
            stayFocused
            onClick={handleOpen}
            ref={refs.setReference}
            {...getReferenceProps()}
          />

          {isMounted && (
            <FloatingPortal>
              <FloatingFocusManager context={context} modal={false}>
                <Content
                  ref={mergeRefs([refs.setFloating, forwardingRef])}
                  style={{
                    ...floatingStyles,
                    ...transitionStyles,
                    zIndex: themeZIndex.popoverDefault.toString()
                  }}
                  {...getFloatingProps()}
                  {...forwardingProps}
                >
                  {children}
                </Content>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </>
      </ContextMenuContext.Provider>
    );
  }
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
`;
