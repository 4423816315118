import { Exclude, Expose, Type } from 'class-transformer';

export const GET_LAST_USED_INSTALLED_APP_PATH =
  'widget/get-last-used-installed-app';

@Exclude()
export class GetLastUsedInstalledAppResponse {
  @Expose()
  @Type(() => GetLastUsedInstalledAppResponseApp)
  app: {
    alias: string;
  } | null;
}

@Exclude()
export class GetLastUsedInstalledAppResponseApp {
  @Expose()
  alias: string;
}
