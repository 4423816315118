import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import { configureScope as configureSentryScope } from '@sentry/nextjs';
import { GetMeResponse } from '@elfsight-universe/service-core-contracts/identity-and-access';
import { ProviderWithChildren } from '@elfsight-universe/ui-common';
import { IdentityAndAccessError } from '@elfsight-universe/service-core-contracts/errors';
import {
  useMeQuery,
  useSignInByMindboxTicketMutation,
  useSignInByAuthTicketMutation,
  useSwitchAccountMutation
} from '@api';
import {
  ScheduledToastType,
  useReplaceQueryParam,
  useScheduledToast
} from '@utils';
import { catchUnhandledError } from '@modules/_error';
import { usePageContext } from './app-page-provider';
import { useClientMessage } from './client-message-provider';
import { useCurrentAccountPid } from './utils/use-current-account-pid';

export const SIGN_IN_URL = '/login';
export const MINDBOX_TICKET_PARAM = 'mt';
export const AUTH_TICKET_PARAM = 'at';
export const REDIRECT_URL_PARAM = 'redirectURL';

export interface IUserContext {
  user?: GetMeResponse;
  refetchUser: () => void;
  isAuthenticated: boolean;
  isLoading: boolean;
  isFetched: boolean;
  currentAccountPid?: string;
}

export const UserContext = createContext<IUserContext | null>(null);

export function AppUserProvider({ children }: ProviderWithChildren) {
  const { replace, isReady: isRouterReady } = useRouter();
  const { guest: isGuestPage } = usePageContext();
  const { reconnect: reconnectClientMessage, close: closeClientMessage } =
    useClientMessage();
  const queryClient = useQueryClient();
  const [, replaceMindboxTicketQueryParam] =
    useReplaceQueryParam(MINDBOX_TICKET_PARAM);
  const [, replaceAuthTicketQueryParam] =
    useReplaceQueryParam(AUTH_TICKET_PARAM);
  const { schedule: scheduleToast } = useScheduledToast({
    type: ScheduledToastType.ACCOUNT_SWITCHED
  });
  const {
    store: storeAccountPid,
    value: storedAccountPid,
    clear: clearAccountPid
  } = useCurrentAccountPid();
  const { mutate: switchAccount } = useSwitchAccountMutation({
    onSuccess: ({ selectedAccountPid }) => {
      scheduleToast(
        'Switched to your Workspace due to lost access to the selected one.'
      );
      storeAccountPid(selectedAccountPid);
      location.reload();
    }
  });
  const [currentAccountPid, setCurrentAccountPid] = useState(storedAccountPid);
  const redirectToLogin = () => {
    const redirectURL = getSearchParam(REDIRECT_URL_PARAM);
    const currentURL =
      document.location.pathname !== '/'
        ? `${document.location.pathname}${document.location.search}`
        : '';

    if (redirectURL) {
      return;
    }

    if (currentURL.match(/\/login(.*)/)) {
      return;
    }

    queryClient.clear();

    return replace(
      `${SIGN_IN_URL}${currentURL ? `?redirectURL=${currentURL}` : ''}`
    );
  };

  const {
    data: user,
    refetch: refetchUser,
    isLoading,
    isFetched
  } = useMeQuery({
    onError: (error) => {
      catchUnhandledError(error);
    }
  });

  const isAuthenticated = !!user;

  const {
    mutate: signInByMindboxTicket,
    isSuccess: authenticatedByMindboxTicket
  } = useSignInByMindboxTicketMutation({
    onError: (error) => {
      replaceMindboxTicketQueryParam(undefined);

      if (!isAuthenticated) {
        catchUnhandledError(error, [
          IdentityAndAccessError.USER_DELETED,
          IdentityAndAccessError.ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT,
          IdentityAndAccessError.USER_CANNOT_LOG_IN_USING_THIS_METHOD
        ]);
        redirectToLogin();
      }
    },
    onSuccess: async () => {
      clearAccountPid();
      await replaceMindboxTicketQueryParam(undefined);
      window.location.reload();
    }
  });

  const { mutate: signInByAuthTicket, isSuccess: authenticatedByAuthTicket } =
    useSignInByAuthTicketMutation({
      onError: (error) => {
        replaceAuthTicketQueryParam(undefined);

        if (!isAuthenticated) {
          catchUnhandledError(error, [
            IdentityAndAccessError.USER_DELETED,
            IdentityAndAccessError.ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT,
            IdentityAndAccessError.USER_CANNOT_LOG_IN_USING_THIS_METHOD
          ]);
          redirectToLogin();
        }
      },
      onSuccess: async () => {
        clearAccountPid();
        await replaceAuthTicketQueryParam(undefined);
        window.location.reload();
      }
    });

  useEffect(() => {
    const authTicket = getSearchParam(AUTH_TICKET_PARAM);
    const mindboxTicket = getSearchParam(MINDBOX_TICKET_PARAM);

    if (authTicket && isFetched) {
      signInByAuthTicket(authTicket);
      return;
    }

    if (mindboxTicket && isFetched) {
      signInByMindboxTicket(mindboxTicket);
      return;
    }

    if (isFetched && !user && !isGuestPage) {
      redirectToLogin();
      return;
    }

    if (!user) {
      return;
    }

    if (user.error === IdentityAndAccessError.NOT_AUTHORIZED) {
      switchAccount({});
      return;
    }

    if (!currentAccountPid) {
      const authenticatedByMagicLink =
        authenticatedByMindboxTicket || authenticatedByAuthTicket;
      const newAccountPid = authenticatedByMagicLink
        ? user.owningAccountPid
        : user.effectiveAccount.pid;
      setCurrentAccountPid(newAccountPid);
      storeAccountPid(newAccountPid);
    }

    if (isAuthenticated) {
      reconnectClientMessage();
    }

    return () => {
      closeClientMessage();
    };
  }, [
    isFetched,
    user,
    currentAccountPid,
    isGuestPage,
    isAuthenticated,
    authenticatedByMindboxTicket,
    authenticatedByAuthTicket,
    isRouterReady
  ]);

  useEffect(() => {
    if (!user) return;

    configureSentryScope((scope) => {
      scope.setUser({ id: user.pid, email: user.email });
    });
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        user,
        refetchUser,
        isLoading,
        isFetched,
        currentAccountPid
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

const getSearchParam = (param: string) => {
  return new URLSearchParams(document.location.search).get(param);
};

export function useUser() {
  const context = useContext(UserContext);

  if (context === null) {
    throw new Error('`useUser` must be nested inside an `AppUserProvider`.');
  }

  return context;
}
