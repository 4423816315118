import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeColor } from '@elfsight-universe/ui-common';
import { MOBILE } from '@elfsight-universe/ui-common';
import { getColorWithAlpha } from '@elfsight-universe/ui-common/src/utils';

export type TopBarProps = PropsWithChildren<{
  backgroundColor: ThemeColor;
  button: ReactNode;
}>;

export const TopBar = ({
  children,
  backgroundColor,
  button,
  ...forwardingProps
}: TopBarProps) => {
  return (
    <Container _backgroundColor={backgroundColor} {...forwardingProps}>
      <Content>{children}</Content>
      <ButtonContainer>{button}</ButtonContainer>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.font.text}
`;

const Container = styled.div<{
  _backgroundColor: ThemeColor;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: ${({ theme, _backgroundColor }) =>
    getColorWithAlpha(theme.colors[_backgroundColor], 0.05)};
  padding: 12px;

  ${MOBILE} {
    gap: 8px;
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div``;
