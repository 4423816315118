import { CSSProperties } from 'react';
import {
  toast,
  ToastBar,
  Toaster as BaseToaster,
  ToastOptions
} from 'react-hot-toast';
import styled, { createGlobalStyle, useTheme } from 'styled-components';
import { Icon } from '@elfsight-universe/ui-common';
import { WarningFilled } from '@elfsight/icons';
import {
  TOAST_OFFLINE_ID,
  TOAST_ONLINE_ID
} from '@modules/_app/app-online-provider';
import { Toast } from '@modules/_app/app-toaster/toast';
import CheckIcon from '@icons/check-20.svg';

const DEFAULT_DURATION = 8000;
const ERROR_DURATION = 12000;
const TOAST_FADE_IN_BOTTOM = 'toastFadeInBottom';
const TOAST_FADE_OUT_BOTTOM = 'toastFadeOutBottom';

export function AppToaster(forwardingProps: ToastOptions) {
  const theme = useTheme();

  const getAnimation = (visible: boolean) =>
    `125ms linear 0s 1 normal forwards ${
      visible ? TOAST_FADE_IN_BOTTOM : TOAST_FADE_OUT_BOTTOM
    }`;

  return (
    <>
      <ToastAnimationKeyframes />
      <BaseToaster
        position="bottom-center"
        containerStyle={{
          ...containerStyles,
          zIndex: theme.zIndex.toast
        }}
        toastOptions={{
          duration: DEFAULT_DURATION,
          style: {
            ...styles,
            color: theme.colors.white
          },
          icon: null,
          success: {
            icon: <SuccessIcon />
          },
          error: {
            duration: ERROR_DURATION,
            icon: <AlertIcon />,
            style: {
              background: theme.colors.alert
            }
          }
        }}
        {...forwardingProps}
      >
        {(t) => {
          if (t.id === TOAST_ONLINE_ID || t.id === TOAST_OFFLINE_ID) {
            return (
              <ToastBar toast={t} position="bottom-center">
                {({ message: component }) => <Inner>{component}</Inner>}
              </ToastBar>
            );
          }

          return (
            <ToastBar
              toast={t}
              position="bottom-center"
              style={{
                animation: getAnimation(t.visible)
              }}
            >
              {({ icon, message }) => (
                <Toast
                  icon={icon}
                  message={<Inner>{message}</Inner>}
                  withClose
                  onClose={() => toast.dismiss(t.id)}
                  {...(t.type === 'error' && { closeIconColor: 'white' })}
                />
              )}
            </ToastBar>
          );
        }}
      </BaseToaster>
    </>
  );
}

const styles: CSSProperties = {
  padding: '16px 20px',
  maxWidth: 520,
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  minHeight: '44px',
  borderRadius: 8,
  background: 'rgb(34, 34, 34)',
  boxShadow: '0 6px 18px 0 rgba(0, 0, 0, 0.50)'
};

const containerStyles: CSSProperties = {
  inset: '24px'
};

// react-hot-toast inner margin fix
const Inner = styled.div`
  > div {
    display: block;
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const AlertIcon = styled(Icon).attrs({
  size: 20,
  fill: 'currentColor',
  component: WarningFilled
})``;

const SuccessIcon = styled(Icon).attrs(({ theme }) => ({
  size: 20,
  fill: theme.colors.success,
  component: CheckIcon
}))``;

const ToastAnimationKeyframes = createGlobalStyle`
    @keyframes ${TOAST_FADE_IN_BOTTOM} {
      from {
        opacity: 0;
        transform: translateY(15%);
      }
      to {
        opacity: 1
      }
    }

    @keyframes ${TOAST_FADE_OUT_BOTTOM} {
      from {
        opacity: 1
      }
      to {
        opacity: 0;
        transform: translateY(15%);
      }
    }
`;
