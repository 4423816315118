import { H2, P, Typography } from '@elfsight-universe/ui-common';
import styled from 'styled-components';
import { ProgressBar } from '@modules/widget-install/request-installation/components/progress-bar';
import { InstallationFormSteps } from '@modules/widget-install/request-installation';

type ProgressBlockProps = {
  progress?: number;
  step: InstallationFormSteps;
};

export function ProgressBlock({
  progress,
  step,
  ...forwardingProps
}: ProgressBlockProps) {
  const caption = {
    1: 'Site and Installation Details',
    2: 'Access and Contact Information'
  }[step];

  return (
    <Container {...forwardingProps}>
      <P mb={20}>
        Fill out the form, and we&apos;ll be pleased to help you integrate the
        widget on your website for free.
      </P>
      <Typography variant="captionUppercase" color="gray50" mb={2}>
        step {step} of 2
      </Typography>
      <H2 mb={10}>{caption}</H2>
      <ProgressBar progress={progress} />
    </Container>
  );
}

const Container = styled.div``;
