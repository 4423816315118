import { Icon, Link } from '@elfsight-universe/ui-common';
import { StarScale } from '@elfsight/icons';

export const TESTIMONIALS_ITEMS = [
  {
    id: '1',
    caption: '2M+',
    description: 'Users globally trust Elfsight to upgrade their websites'
  },
  {
    id: '2',
    caption: '#1',
    description: 'Top service in the world when it comes to website widgets'
  },
  {
    id: '3',
    caption: (
      <>
        <Icon size={28} component={StarScale} fill="warning" /> 4.9
      </>
    ),
    description: (
      <>
        Rating from{' '}
        <Link
          color="accent"
          href="https://www.g2.com/products/elfsight/reviews"
        >
          thousands of reviews
        </Link>{' '}
        gathered over our 11-year history
      </>
    )
  },
  {
    id: '4',
    caption: '80+',
    description: (
      <>
        Widgets are available in our consistently{' '}
        <Link color="accent" href="https://elfsight.com/widgets">
          growing collection
        </Link>
      </>
    )
  }
];
