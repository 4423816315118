import { useMemo } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { Input } from '@elfsight-universe/ui-common';
import { CreateInstallationRequestAppealRequest } from '@elfsight-universe/service-core-contracts/appeal';
import { requestInstallationCollaboratorTutorialsList } from './constants/request-installation-collaborator-tutorials-list';
import { CollaboratorBlock, WithoutCollaboratorBlock } from './components';

export function RequestInstallationFormStep2() {
  const {
    register,
    formState: { errors },
    watch,
    clearErrors
  } = useFormContext<CreateInstallationRequestAppealRequest>();

  const watchPlatform = watch('platform');

  const withCollaborator = useMemo(
    () =>
      requestInstallationCollaboratorTutorialsList.find(
        ({ platform }) => platform === watchPlatform
      ),
    [watchPlatform]
  );

  return (
    <Container>
      {withCollaborator ? <CollaboratorBlock /> : <WithoutCollaboratorBlock />}

      <Input
        {...register('contactEmail')}
        error={errors?.contactEmail?.message}
        label="Contact Email"
        placeholder="example@gmail.com"
        type="email"
        autoComplete="email"
        onChange={() => clearErrors('contactEmail')}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 20px;
`;
