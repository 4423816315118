import { cloneElement, ReactElement, useEffect, useMemo } from 'react';
import GradientParser, {
  AngularNode,
  ColorStop,
  DirectionalNode,
  LinearGradientNode
} from 'gradient-parser';
import { injectSVGDef } from './svg-defs-root';

export type SVGLinearGradientProps = {
  children: ReactElement;
  fill: string;
  linearGradient: string | undefined;
};

export function SVGLinearGradientFill({
  children,
  fill,
  linearGradient
}: SVGLinearGradientProps) {
  const parsedGradient = useMemo(() => parse(linearGradient), [linearGradient]);
  const linearGradientId = generateId(parsedGradient);

  useEffect(() => {
    if (!parsedGradient || !linearGradientId) {
      return;
    }

    const { colorStops, orientation } = parsedGradient;

    injectSVGDef(
      <linearGradient
        id={linearGradientId}
        {...orientationToCoordinates(orientation)}
      >
        {colorStops.map((stop, i) => (
          <stop key={i} stopColor={toColor(stop)} offset={toOffset(stop)} />
        ))}
      </linearGradient>,
      linearGradientId
    );
  }, [parsedGradient]);

  return cloneElement(children, {
    ...children.props,
    fill: linearGradientId ? `url(#${linearGradientId}) ${fill}` : fill
  });
}

const generateId = (parsedGradient: LinearGradientNode | undefined) => {
  if (!parsedGradient) {
    return;
  }

  const { colorStops, orientation } = parsedGradient;

  const colorStopsIds = colorStops
    .flatMap(({ length, type, value }) => [
      type,
      Array.isArray(value) ? value.join('-') : value,
      length?.value
    ])
    .join('-');

  return [orientation?.type, orientation?.value, colorStopsIds].join('-');
};

const parse = (linearGradient: string | undefined) => {
  if (!linearGradient) return;

  try {
    const parsed = GradientParser.parse(linearGradient)[0];

    if (parsed.type !== 'linear-gradient') {
      console.warn(
        `[SVGLinearGradientFill]: Unsupported gradient type "${parsed.type}"`
      );
      return;
    }

    return parsed;
  } catch (e) {
    console.log(`[SVGLinearGradientFill]: Gradient parse failed "${e}"`);
    return;
  }
};

const toColor = ({ type, value }: ColorStop) => {
  if (type === 'literal') {
    return value;
  }
  if (type === 'hex') {
    return `#${value}`;
  }
  if (type === 'rgb' || type === 'rgba') {
    return `${type}(${value.join(', ')})`;
  }
};

const toOffset = ({ length }: ColorStop) => {
  if (!length) {
    return undefined;
  }
  return `${length.value}${length.type}`;
};

const orientationToCoordinates = (
  orientation?: DirectionalNode | AngularNode
) => {
  if (!orientation) {
    return;
  }

  if (orientation.type === 'directional') {
    console.warn(
      `[SVGLinearGradientFill]: Unsupported orientation type "${orientation.type}"`
    );
    return;
  }

  if (orientation.type === 'angular') {
    const primitiveMapped = {
      270: {
        x1: '.5',
        y1: undefined,
        x2: '.5',
        y2: undefined
      },
      180: {
        x1: '.5',
        y1: undefined,
        x2: '.5',
        y2: '1'
      },
      90: {
        x1: undefined,
        y1: undefined,
        x2: undefined,
        y2: undefined
      },
      0: {
        x1: '.5',
        y1: '1',
        x2: '.5',
        y2: undefined
      }
    }[orientation.value];

    if (!primitiveMapped) {
      console.warn(
        `[SVGLinearGradientFill]: Unsupported orientation mapping value "${orientation.value}"`
      );
    }

    return primitiveMapped;
  }
};
