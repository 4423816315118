import styled, { useTheme } from 'styled-components';
import {
  DESKTOP,
  MOBILE,
  Modal,
  ModalProps,
  TabsNav,
  TabsProvider
} from '@elfsight-universe/ui-common';
import { useIsMobile } from '@modules/_app';
import { useReplaceQueryParam } from '@utils';
import { DISABLED_SHARE_LINK_APP_ALIASES } from '@constants';
import { EmbedToWixTab } from '@modules/widget-install/embed-to-wix/embed-to-wix-tab';
import { useWixIntegration } from '@modules/wix-integration/contexts/wix-integration';
import { EmbedToWebsiteTab } from './embed-code';
import { ShareLinkTab } from './share-link';
import {
  WIDGET_INSTALL_TAB_QUERY_PARAM,
  WidgetInstallTab
} from './widget-install-constants';
import { RequestInstallationTab } from './request-installation';
import { WidgetInstallData } from './widget-install-data.interface';
import { RequestInstallationFormProvider } from './request-installation/request-installation-form-provider';

export type WidgetInstallModalProps = Omit<ModalProps, 'title' | 'actions'> &
  WidgetInstallData;

export const WIDGET_INSTALL_PID_QUERY_PARAM = 'install';

export function WidgetInstallModal({
  onRequestClose,
  widgetPid,
  isDraft,
  onPublishDraft,
  isPublishingDraft,
  appAlias,
  plan,
  ...forwardingProps
}: WidgetInstallModalProps) {
  const isMobile = useIsMobile();
  const { zIndex } = useTheme();
  const [queryParam, replaceQueryParam] = useReplaceQueryParam(
    WIDGET_INSTALL_TAB_QUERY_PARAM
  );
  const wixIntegration = useWixIntegration();
  const data = {
    widgetPid,
    appAlias,
    isDraft,
    onPublishDraft,
    isPublishingDraft,
    plan
  };

  const isDisabledShareLink =
    DISABLED_SHARE_LINK_APP_ALIASES.includes(appAlias);

  const widgetInstallTabs = [
    {
      title: 'Embed to Wix',
      id: WidgetInstallTab.WIX,
      disabled: !wixIntegration.isWixSession
    },
    {
      title: 'Embed Code',
      id: WidgetInstallTab.WEBSITE,
      disabled: false
    },
    {
      title: 'Share Link',
      id: WidgetInstallTab.SHARE,
      disabled: isDisabledShareLink
    },
    {
      title: isMobile ? 'Help' : 'Request Installation',
      id: WidgetInstallTab.INSTALLATION,
      disabled: false
    }
  ];

  const widgetInstallTabsContent = {
    [WidgetInstallTab.WIX]: (
      <EmbedToWixTab tabID={WidgetInstallTab.WIX} widgetPid={widgetPid} />
    ),
    [WidgetInstallTab.WEBSITE]: (
      <EmbedToWebsiteTab tabID={WidgetInstallTab.WEBSITE} {...data} />
    ),
    [WidgetInstallTab.SHARE]: (
      <ShareLinkTab tabID={WidgetInstallTab.SHARE} {...data} />
    ),
    [WidgetInstallTab.INSTALLATION]: (
      <RequestInstallationTab tabID={WidgetInstallTab.INSTALLATION} />
    )
  };

  const finalInstallTabs = widgetInstallTabs.filter(
    ({ disabled }) => !disabled
  );
  const finalInstallTabsContent = finalInstallTabs.map(({ id }) => ({
    ...widgetInstallTabsContent[id],
    key: id
  }));

  return (
    <Modal
      maxWidth={800}
      onRequestClose={(e) => {
        onRequestClose && onRequestClose(e);
        replaceQueryParam(undefined);
      }}
      withClose
      padding={[0, 0]}
      zIndex={zIndex.installModal}
      {...forwardingProps}
    >
      <RequestInstallationFormProvider
        widgetPid={widgetPid}
        appAlias={appAlias}
      >
        <Container>
          <TabsProvider
            tabs={finalInstallTabs}
            defaultTab={
              wixIntegration.isWixSession
                ? WidgetInstallTab.WIX
                : WidgetInstallTab.WEBSITE
            }
            queryParam={queryParam}
            onSetActiveTab={(value: string) => {
              replaceQueryParam(value);
            }}
          >
            <StyledTabsNav
              withBorder
              largeItems={!isMobile}
              height={isMobile ? 48 : 60}
            />

            <ContentWrapper>
              <ContentBody>
                {finalInstallTabsContent.map((tab) => tab)}
              </ContentBody>
            </ContentWrapper>
          </TabsProvider>
        </Container>
      </RequestInstallationFormProvider>
    </Modal>
  );
}

const Container = styled.div`
  min-height: 732px;
  max-height: calc(100dvh - 40px);
  display: flex;
  flex-direction: column;
  align-self: start;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentBody = styled.div`
  padding: 28px 32px 20px 32px;
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledTabsNav = styled(TabsNav)`
  padding: 0 32px;
  margin-top: 24px;
  flex-shrink: 0;

  ul {
    ${DESKTOP} {
      gap: 0 40px;
    }
    ${MOBILE} {
      gap: 0 28px;
    }
  }
`;
