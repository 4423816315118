import styled, { css } from 'styled-components';
import {
  Icon,
  UserAvatar,
  UserAvatarProps
} from '@elfsight-universe/ui-common';
import AccountMemberInvitationAvatar from '../assets/account-member-invitation.png';
import AccountMemberInvitationClock from '../assets/account-member-invitation-clock.svg';

export type CollaboratorsUserAvatarProps = {
  profilePictureURL?: string | null;
  isInvitation?: boolean;
  isDeactivated?: boolean;
  placeholderArgs: UserAvatarProps['placeholderArgs'];
};

export function CollaboratorsUserAvatar({
  profilePictureURL,
  isDeactivated,
  placeholderArgs,
  isInvitation
}: CollaboratorsUserAvatarProps) {
  return (
    <Container>
      <StyledUserAvatar
        size={36}
        src={
          isInvitation ? AccountMemberInvitationAvatar.src : profilePictureURL
        }
        placeholderArgs={placeholderArgs}
        $withOpacity={isDeactivated}
      />
      {isInvitation && (
        <PendingClock component={AccountMemberInvitationClock} size={20} />
      )}
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  display: flex;
  align-self: start;
`;

const StyledUserAvatar = styled(UserAvatar)<{ $withOpacity?: boolean }>`
  ${({ $withOpacity }) =>
    $withOpacity &&
    css`
      opacity: 0.3;
      mix-blend-mode: luminosity;
    `}
`;

const PendingClock = styled(Icon)`
  position: absolute;
  bottom: -2px;
  right: -5px;
`;
