import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import {
  adFeatureResolver,
  appsCountFeatureResolver,
  supportFeatureResolver,
  viewsLimitFeatureResolver,
  websitesLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  additionalFeaturesFeatureResolver,
  installationServiceFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver
} from './feature-resolvers';
import { aiReviewsTranslationFeatureResolver } from './feature-resolvers/ai-reviews-translation-feature-resolver';
import { advancedCustomizationFeatureResolver } from './feature-resolvers/advanced-customization';

export const enterpriseFeatureResolvers: FeatureResolver[] = [
  appsCountFeatureResolver,
  websitesLimitFeatureResolver,
  viewsLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  advancedCustomizationFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  adFeatureResolver,
  additionalFeaturesFeatureResolver
];
