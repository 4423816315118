export const formatRelativeDate = (date: Date | string) => {
  const inputDate = date instanceof Date ? date : new Date(date);
  const now = new Date();
  const diff = now.getTime() - inputDate.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  if (seconds < 60) {
    return 'Just Now';
  }

  if (minutes < 60) {
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  }

  if (hours < 24) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }

  return formatter.format(inputDate);
};
