import { IconInject } from './icon-inject';
import { SVGLinearGradientFill } from './svg-linear-gradient-fill';

export type AppIconProps = {
  icon: string | null | undefined;
  backgroundColor?: string;
  fill?: string;
  linearGradient?: string;
  size?: number;
};

export function AppIcon({
  icon,
  size = 18,
  fill = '#fff',
  linearGradient,
  ...forwardingProps
}: AppIconProps) {
  if (!icon) return null;

  return (
    <SVGLinearGradientFill fill={fill} linearGradient={linearGradient}>
      <IconInject src={icon} size={size} {...forwardingProps} />
    </SVGLinearGradientFill>
  );
}
