import styled from 'styled-components';
import NextImage from 'next/image';
import { H3, H4, Link, Typography } from '@elfsight-universe/ui-common';
import supportAgent from '@public/assets/support-agent-sofia.jpg';
import pricing from '@public/assets/pricing.png';

interface PricingTableEnterpriseEmpty {
  appName?: string;
}

export function PricingTableEnterpriseEmpty({
  appName
}: PricingTableEnterpriseEmpty) {
  return (
    <Container>
      <NextImage src={pricing} height={80} width={120} objectFit="contain" />
      <Text>
        To learn more about Enterprise plans and terms for {appName}, please
        reach out to us at{' '}
        <Link href="mailto:support@elfsight.com" color="accent">
          support@elfsight.com
        </Link>
        .
        <br />
        <br />
        Our manager Sofia will get back to you shortly.
      </Text>
      <AccountManagerBlock>
        <AccountManagerAvatar>
          <NextImage src={supportAgent} height={40} width={40} layout="fixed" />
        </AccountManagerAvatar>

        <AccountManagerText>
          <H4>Sofia</H4>
          <Typography variant="captionSmall" color="gray50">
            Account Manager
          </Typography>
        </AccountManagerText>
      </AccountManagerBlock>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 72px 20px 92px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 12px ${({ theme }) => theme.colors.gray10};
  border-radius: 8px;
`;

const Text = styled(H3)`
  text-align: center;
  max-width: 650px;
`;

const AccountManagerBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AccountManagerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const AccountManagerAvatar = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto;

  img {
    border-radius: 50%;
    overflow: hidden;
  }
`;
