import styled, { css } from 'styled-components';
import { ThemeColor } from '../theme';

// @TODO REFACTOR tables

export const TH = styled.th<{ _width?: string; _alignRight?: boolean }>`
  display: table-cell;
  ${({ theme }) => theme.font.captionSmallUppercase};
  font-weight: 500;
  text-align: left;
  vertical-align: inherit;
  box-sizing: border-box;
  height: 32px;
  padding: 0;
  ${({ _width }) =>
    _width &&
    css`
      width: ${_width};
    `}
  ${({ _alignRight }) =>
    _alignRight &&
    css`
      text-align: right;
    `}
  color: ${({ theme }) => theme.colors.gray50};
  ${({ onClick, theme }) =>
    onClick &&
    css`
      cursor: pointer;
      :hover {
        color: ${theme.colors.gray70};
      }
    `}
`;

export const TD = styled.td<{ _alignRight?: boolean }>`
  display: table-cell;
  ${({ theme }) => theme.font.text}
  vertical-align: inherit;
  box-sizing: border-box;
  ${({ _alignRight }) =>
    _alignRight &&
    css`
      text-align: right;
    `}
  //height: 64px;
  transition: border-color 0.2s;
`;

export const TR = styled.tr<{
  _backgroundColor?: ThemeColor;
  _borderWidth?: number;
  _color?: ThemeColor;
  _isMain?: boolean;
  _height?: number;
  href?: string;
}>`
  display: table-row;
  position: relative;
  outline: 0;
  vertical-align: middle;
  cursor: ${({ onClick, href }) => (onClick || href ? 'pointer' : 'default')};
  transition: background 0.2s;

  ${({ _backgroundColor, theme }) =>
    _backgroundColor && `background-color:${theme.colors[_backgroundColor]};`}
  color: ${({ theme, _color = 'gray70' }) => theme.colors[_color]};

  ${({ _borderWidth = 1, _height = 64 }) => css`
    & ${TD} {
      border-top: ${_borderWidth}px solid ${({ theme }) => theme.colors.gray10};
      height: ${_height}px;
    }
    &:last-child ${TD} {
      border-bottom: ${_borderWidth}px solid
        ${({ theme }) => theme.colors.gray10};
    }
  `}

  ${({ onClick, href, theme: { colors } }) =>
    (onClick || href) &&
    css`
      &:hover {
        background: ${colors.gray5};
      }

      &:hover ${TD} {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
      }
      &:hover + & ${TD} {
        border-top-color: transparent;
      }
    `};
`;

export const THead = styled.thead<{ _withBackground?: boolean }>`
  display: table-header-group;
  background-color: ${({ theme, _withBackground }) =>
    _withBackground ? theme.colors.background : 'transparent'};

  ${TR}:first-child {
    ${TD}, ${TH} {
      &:first-child {
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
    }
  }

  ${TR}:last-child:not(:only-child) {
    ${TD}, ${TH} {
      &:first-child {
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }

  ${TR} {
    & ${TD} {
      border-top: none;
    }
    &:last-child ${TD} {
      border-bottom: none;
    }
  }
`;

export const TBody = styled.tbody`
  display: table-row-group;
`;

export const TFoot = styled.tfoot`
  display: table-footer-group;
`;

export const Table = styled.table<{
  _gap?: [number, number];
  _omitBoundaryGap?: boolean;
}>`
  display: table;
  border-collapse: collapse;
  width: 100%;

  ${({ _gap: [gapY, gapX] = [12, 12], _omitBoundaryGap }) =>
    css`
      ${TD} {
        padding-top: ${gapY}px;
        padding-bottom: ${gapY}px;
      }

      ${TD}, ${TH} {
        :last-child {
          padding-right: ${_omitBoundaryGap ? '0' : gapX}px;
        }
        :not(:last-child) {
          padding-right: ${gapX / 2}px;
        }
        :first-child {
          padding-left: ${_omitBoundaryGap ? '0' : gapX}px;
        }
        :not(:first-child) {
          padding-left: ${gapX / 2}px;
        }
      }
    `}
`;
