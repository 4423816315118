import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_WIDGET_PATH,
  GetWidgetResponse,
  GetWidgetResponseError
} from '@elfsight-universe/service-core-contracts/widget/get-widget';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useWidgetQuery(
  pid: string | undefined,
  options: UseQueryOptions<GetWidgetResponse, GetWidgetResponseError> = {}
) {
  const { isAuthenticated } = useUser();

  return useQuery<GetWidgetResponse, GetWidgetResponseError>(
    [GET_WIDGET_PATH, pid],
    () =>
      client
        .get(GET_WIDGET_PATH, {
          searchParams: { pid: pid as string },
          retry: { limit: 0 }
        })
        .json(),
    {
      cacheTime: 0, // @TODO gcTime for v5
      enabled: Boolean(pid) && isAuthenticated,
      refetchOnWindowFocus: false,
      ...options
    }
  );
}
