import {
  ComponentType,
  forwardRef,
  useMemo,
  ReactNode,
  ButtonHTMLAttributes
} from 'react';
import { UrlObject } from 'url';
import { buttonColorsMap, defaultStateColors } from './button-colors';
import {
  ButtonContainer,
  ButtonSlot,
  ButtonContent,
  ButtonEllipsis
} from './button-layout';
import { NextLinkComposed } from '../../next';
import { ThemeColor, useTheme } from '../../theme';
import {
  getColorWithAlpha,
  getInvertedColor,
  getThemeOrCustomColor
} from '../../utils';
import { Icon } from '../icons';
import { Loader } from '../loader';

export type ButtonSize = 'large' | 'medium' | 'small';
type ButtonVariation = keyof typeof buttonColorsMap;

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  iconSize?: number;
  iconColor?: string | ThemeColor;
  iconHoverColor?: string | ThemeColor;
  leftIcon?: ComponentType;
  rightIcon?: ComponentType;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  textColor?: string | ThemeColor;
  interactTextColor?: string | ThemeColor;
  backgroundColor?: string | ThemeColor;
  selectedBackgroundColor?: string | ThemeColor;
  fontWeight?: number;
  variation?: ButtonVariation;
  fullWidth?: boolean;
  fontSize?: number;
  large?: boolean;
  small?: boolean;
  height?: number;
  center?: boolean;
  active?: boolean;
  selected?: boolean;
  borderRadius?: number;
  padding?: number;
  negativeOffset?: number;
  href?: string | UrlObject;
  target?: string;
  isLoading?: boolean;
  disableShrink?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function _Button(
    {
      variation = 'primary',
      textColor,
      interactTextColor,
      backgroundColor,
      selectedBackgroundColor,
      large,
      small,
      height,
      fullWidth,
      borderRadius = 4,
      fontWeight,
      fontSize,
      center,
      children,
      iconSize,
      iconColor,
      iconHoverColor,
      leftIcon: LeftIconComponent,
      rightIcon: RightIconComponent,
      leftSlot,
      rightSlot,
      padding,
      negativeOffset,
      active,
      selected,
      onClick,
      href,
      isLoading,
      disableShrink,
      ...forwardingProps
    },
    forwardingRef
  ) {
    const theme = useTheme();
    const colors = useMemo(() => {
      if (backgroundColor) {
        return {
          $backgroundColor: backgroundColor,
          $textColor: textColor || getInvertedColor(backgroundColor),
          ...defaultStateColors,
          $selectedOverlayColor:
            selectedBackgroundColor ||
            getColorWithAlpha(theme.colors.black, 0.1)
        };
      }

      if (textColor) {
        const fromColor = getThemeOrCustomColor(textColor, theme);
        return {
          $backgroundColor: backgroundColor || undefined,
          $textColor: textColor,
          $interactTextColor: interactTextColor,
          $hoverOverlayColor: getColorWithAlpha(fromColor, 0.05),
          $activeOverlayColor: getColorWithAlpha(fromColor, 0.1),
          $selectedOverlayColor:
            selectedBackgroundColor || getColorWithAlpha(fromColor, 0.1)
        };
      }

      if (selectedBackgroundColor) {
        return {
          ...buttonColorsMap[variation],
          $selectedOverlayColor: selectedBackgroundColor
        };
      }

      return buttonColorsMap[variation];
    }, [
      variation,
      textColor,
      interactTextColor,
      backgroundColor,
      selectedBackgroundColor
    ]);

    const size: ButtonSize =
      (small && 'small') || (large && 'large') || 'medium';
    const finalFontSize =
      fontSize || { large: 16, medium: 14, small: 14 }[size];
    const finalIconSize =
      iconSize || { large: 20, medium: 16, small: 12 }[size];
    const finalHeight = height || { large: 48, medium: 36, small: 32 }[size];
    const finalFontWeight =
      fontWeight ||
      (variation === 'text'
        ? { large: 500, medium: 500, small: 500 }[size]
        : { large: 600, medium: 600, small: 500 }[size]);

    const fromSizePadding =
      variation === 'text'
        ? { large: 12, medium: 8, small: 4 }[size]
        : { large: 20, medium: 16, small: 12 }[size];
    const finalPadding = padding || fromSizePadding;

    const Component = href ? NextLinkComposed : 'button';

    return (
      <ButtonContainer
        $fullWidth={fullWidth}
        $fontWeight={finalFontWeight}
        $fontSize={finalFontSize}
        $padding={finalPadding}
        $height={finalHeight}
        $borderRadius={borderRadius}
        $active={active}
        $selected={selected}
        $center={center}
        $negativeOffset={negativeOffset}
        $disableShrink={disableShrink}
        $iconHoverColor={iconHoverColor}
        onClick={isLoading ? undefined : onClick}
        ref={forwardingRef}
        href={href}
        as={Component}
        {...colors}
        {...forwardingProps}
      >
        {isLoading && (
          <Loader
            size={finalHeight / 2}
            spacing={0}
            color={colors.$textColor}
            absolute
          />
        )}

        <ButtonContent style={{ opacity: isLoading ? 0 : 1 }}>
          {LeftIconComponent && (
            <ButtonSlot _left>
              <Icon
                size={finalIconSize}
                fill={iconColor}
                component={LeftIconComponent}
              />
            </ButtonSlot>
          )}
          {leftSlot && <ButtonSlot _left>{leftSlot}</ButtonSlot>}

          <ButtonEllipsis>{children}</ButtonEllipsis>

          {RightIconComponent && (
            <ButtonSlot _right>
              <Icon
                size={finalIconSize}
                fill={iconColor}
                component={RightIconComponent}
              />
            </ButtonSlot>
          )}
          {rightSlot && <ButtonSlot _right>{rightSlot}</ButtonSlot>}
        </ButtonContent>
      </ButtonContainer>
    );
  }
);
