import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeColor } from '../theme';
import { MOBILE } from '../breakpoints';
import { getColorWithAlpha } from '../utils';

export type CalloutProps = PropsWithChildren<{
  icon?: ReactNode;
  backgroundColor: ThemeColor;
  button?: ReactNode;
}>;

export const Callout = ({
  children,
  icon,
  backgroundColor,
  button,
  ...forwardingProps
}: CalloutProps) => {
  return (
    <Container
      _withButton={Boolean(button)}
      _backgroundColor={backgroundColor}
      {...forwardingProps}
    >
      {icon}

      <Content>{children}</Content>

      {button && <ButtonContainer>{button}</ButtonContainer>}
    </Container>
  );
};

const Content = styled.div`
  ${({ theme }) => theme.font.text}
  flex-basis: 100%;
`;

const Container = styled.div<{
  _backgroundColor: ThemeColor;
  _withButton: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background-color: ${({ theme, _backgroundColor }) =>
    getColorWithAlpha(theme.colors[_backgroundColor], 0.1)};
  border-radius: 4px;
  padding: 12px 12px;

  ${MOBILE} {
    flex-direction: column;
    ${Content} {
      text-align: center;
    }
  }
`;

const ButtonContainer = styled.div``;
