import styled from 'styled-components';
import { ToggleSwitch } from '@elfsight-universe/ui-common/src/components/controls';
import { DESKTOP, MOBILE } from '@elfsight-universe/ui-common/src/breakpoints';
import { BillingInterval } from '@elfsight-universe/service-core-contracts/billing';
import { exactRoundingFloatValue } from '../utils';

export type PricingTablePeriodToggleProps = {
  billingInterval: BillingInterval;
  onChange: (period: BillingInterval) => void;
  annualSale?: number;
};

export function PricingTablePeriodToggle({
  billingInterval,
  onChange,
  annualSale = 0.17
}: PricingTablePeriodToggleProps) {
  const handleChange = () =>
    onChange(
      {
        [BillingInterval.MONTHLY]: BillingInterval.YEARLY,
        [BillingInterval.YEARLY]: BillingInterval.MONTHLY
      }[billingInterval]
    );

  return (
    <Container>
      <Label
        onClick={() => onChange(BillingInterval.MONTHLY)}
        _active={billingInterval === BillingInterval.MONTHLY}
      >
        Monthly
      </Label>

      <ToggleSwitch
        onChange={handleChange}
        checked={billingInterval === BillingInterval.YEARLY}
        uncheckedColor="gray50"
        checkedColor="gray50"
      />

      <Label
        onClick={() => onChange(BillingInterval.YEARLY)}
        _active={billingInterval === BillingInterval.YEARLY}
      >
        Annually{' '}
        {billingInterval === BillingInterval.MONTHLY && (
          <Sale>(save {exactRoundingFloatValue(annualSale * 100)}%)</Sale>
        )}
      </Label>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${DESKTOP} {
    margin-bottom: 32px;
  }
  ${MOBILE} {
    margin-bottom: 16px;
  }
`;

const Label = styled.label<{ _active: boolean }>`
  ${({ theme }) => theme.font.title4};
  color: ${({ _active, theme: { colors } }) =>
    _active ? colors.black : colors.gray50};
  cursor: pointer;

  ${DESKTOP} {
    flex-basis: 20%;
    :first-child {
      text-align: right;
    }
  }
`;

const Sale = styled.span`
  ${({ theme }) => theme.font.title4};
  color: ${({ theme }) => theme.colors.brand};
`;
