import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const DELETE_WIDGET_PATH = 'widget/delete-widget';

export class DeleteWidgetRequest {
  @IsUUID()
  pid: string;
}

export type DeleteWidgetResponseError =
  | WidgetError.WIDGET_DOES_NOT_EXIST
  | WidgetError.WIDGET_IS_ALREADY_DELETED;
