import styled, { css } from 'styled-components';
import {
  getColorWithAlpha,
  theme,
  Typography
} from '@elfsight-universe/ui-common';

export interface ProjectIconProps {
  projectName: string;
  projectThumbnailURL?: string;
  projectActive: boolean;
  small?: boolean;
}

export function ProjectIcon({
  projectThumbnailURL,
  projectName,
  projectActive,
  small = false
}: ProjectIconProps) {
  return (
    <ProjectThumbnail
      _active={projectActive}
      _hasProjectThumbnail={!!projectThumbnailURL}
      _small={small}
    >
      {projectThumbnailURL ? (
        <ProjectThumbnailImage
          src={projectThumbnailURL}
          alt={projectName}
          width={small ? 18 : 20}
          height={small ? 18 : 20}
        />
      ) : (
        <ProjectThumbnailChar
          variant={small ? 'title4Semibold' : 'title3Semibold'}
          color="accent"
        >
          {projectName[0]}
        </ProjectThumbnailChar>
      )}
    </ProjectThumbnail>
  );
}

const ProjectThumbnail = styled.div<{
  _hasProjectThumbnail: boolean;
  _small: boolean;
  _active: boolean;
}>`
  height: ${({ _small }) => (_small ? '30px' : '36px')};
  min-width: ${({ _small }) => (_small ? '30px' : '36px')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, _hasProjectThumbnail }) =>
      _hasProjectThumbnail
        ? theme.colors.gray10
        : theme.colors.accentTransparent};

  ${({ _hasProjectThumbnail }) =>
    !_hasProjectThumbnail &&
    css`
      background: linear-gradient(
        180deg,
        ${getColorWithAlpha(theme.colors.accent, 0.15)} 0%,
        ${getColorWithAlpha(theme.colors.accent, 0.09)} 100%
      );
    `}

  ${({ _active }) =>
    !_active &&
    css`
      opacity: 0.3;
      mix-blend-mode: luminosity;
    `}
`;

const ProjectThumbnailChar = styled(Typography)`
  text-transform: uppercase;
`;

const ProjectThumbnailImage = styled.img``;
