import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export type BadgeProps = PropsWithChildren<{
  offset?: number | [number, number];
  static?: boolean;
}>;

export function Badge({
  children,
  offset = 0,
  static: staticMode = false
}: BadgeProps) {
  if (!children) return null;

  return (
    <>
      {staticMode && <>&nbsp;</>}
      <Container _offset={offset} _static={staticMode}>
        {children}
      </Container>
    </>
  );
}

const Container = styled.span<{
  _offset: number | [number, number];
  _static: boolean;
}>`
  ${({ _static, _offset }) => {
    if (_static) {
      return css`
        position: relative;
      `;
    }

    return Array.isArray(_offset)
      ? css`
          position: absolute;
          top: ${_offset[0]}px;
          right: ${_offset[1]}px;
        `
      : css`
          position: absolute;
          top: ${_offset}px;
          right: ${_offset}px;
        `;
  }}
  z-index: 1;
  min-width: 16px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  border-radius: 100px;
  ${({ theme }) => theme.font.captionSmall};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.alert};
  user-select: none;
`;
