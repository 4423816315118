import { CreateFeatureOptions } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';

export const installationServiceFeatureResolver = ({
  isLitePlan
}: CreateFeatureOptions) => {
  return {
    excluded: isLitePlan,
    text: (
      <FeatureTooltip
        content={
          <>
            Free Installation Service includes assistance from our support team
            in installing the widget on your website. Our experts are ready to
            help resolve any issues related to the installation process,
            ensuring a smooth and successful setup. This service is provided at
            no additional cost to you.
          </>
        }
      >
        <>Free installation service</>
      </FeatureTooltip>
    )
  };
};
