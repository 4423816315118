import { Exclude, Expose, Type } from 'class-transformer';

export const GET_COLLABORATORS_PATH = 'iam/get-collaborators';

@Exclude()
export class GetCollaboratorsResponse {
  @Expose()
  @Type(() => GetCollaboratorsResponseAccountMember)
  accountMembers: GetCollaboratorsResponseAccountMember[];

  @Expose()
  @Type(() => GetCollaboratorsResponseAccountMemberInvitation)
  accountMemberInvitations: GetCollaboratorsResponseAccountMemberInvitation[];
}

@Exclude()
export class GetCollaboratorsResponseAccountMember {
  @Expose()
  pid: string;

  @Expose()
  @Type(() => GetCollaboratorsResponseAccountMemberUser)
  user: {
    email: string;
    userProfile: {
      firstName: string | null;
      lastName: string | null;
    } | null;
  };

  @Expose()
  isOwner: boolean;

  @Expose()
  isYou: boolean;

  @Expose()
  isDeactivated: boolean;

  @Expose()
  profilePictureURL: string | null;

  @Expose()
  lastActivityAt?: Date;
}

@Exclude()
export class GetCollaboratorsResponseAccountMemberInvitation {
  @Expose()
  pid: string;

  @Expose()
  email: string;

  @Expose()
  isDeactivated: boolean;
}

@Exclude()
export class GetCollaboratorsResponseAccountMemberUser {
  @Expose()
  email: string;

  @Expose()
  @Type(() => GetCollaboratorsResponseAccountMemberUserProfile)
  userProfile: {
    firstName: string | null;
    lastName: string | null;
  } | null;
}

@Exclude()
export class GetCollaboratorsResponseAccountMemberUserProfile {
  @Expose()
  firstName: string | null;

  @Expose()
  lastName: string | null;
}
