import { IncomingHttpHeaders } from 'http';
import { WidgetError } from '@elfsight-universe/service-core-contracts/errors';
import {
  GET_WIDGET_PATH,
  GetWidgetRequest,
  GetWidgetResponse,
  GetWidgetResponseError
} from '@elfsight-universe/service-core-contracts/widget/get-widget';

const { NEXT_PUBLIC_APP_DASHBOARD__BASE_URL } = process.env;

export async function getWidgetServerSide(
  { pid }: GetWidgetRequest,
  incomingHeaders?: IncomingHttpHeaders
): Promise<{
  data?: GetWidgetResponse;
  errorCode?: number;
}> {
  const headers = new Headers({
    Accept: 'application/json'
  });

  if (incomingHeaders?.cookie) {
    headers.append('cookie', incomingHeaders.cookie);
  }

  const query = new URLSearchParams({ pid });
  const response = await fetch(
    `${NEXT_PUBLIC_APP_DASHBOARD__BASE_URL}/core/${GET_WIDGET_PATH}?${query}`,
    {
      method: 'GET',
      headers,
      credentials: 'include'
    }
  );

  const data = await response.json();

  if (!response.ok) {
    const errorMessage = data.message as GetWidgetResponseError;
    const errorCode =
      errorMessage === WidgetError.WIDGET_DOES_NOT_EXIST
        ? 404
        : response.status;

    return { errorCode, data: undefined };
  }

  return {
    errorCode: undefined,
    data: data as GetWidgetResponse
  };
}
