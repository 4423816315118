import { IsBoolean, IsOptional, IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const UPDATE_WIDGET_META_PATH = 'widget/update-widget-meta';

export class UpdateWidgetMetaRequest {
  @IsUUID()
  pid: string;

  @IsBoolean()
  @IsOptional()
  installationProposed?: boolean;

  @IsBoolean()
  @IsOptional()
  installationConfirmed?: boolean;
}

export type UpdateWidgetMetaResponseError = WidgetError.WIDGET_DOES_NOT_EXIST;
