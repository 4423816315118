export const requestInstallationCollaboratorTutorialsList = [
  {
    platform: 'Shopify',
    articleID: '5d7a482c04286364bc8f09b9',
    articleURL:
      'https://help.elfsight.com/article/149-how-to-provide-access-to-your-shopify-website'
  },
  {
    platform: 'WordPress',
    articleID: '5d5fc8ad04286350aeeaedde',
    articleURL:
      'https://help.elfsight.com/article/134-how-to-provide-access-to-your-wordpress-website'
  },
  {
    platform: 'Squarespace',
    articleID: '5d84a22e2c7d3a7e9ae1690d',
    articleURL:
      'https://help.elfsight.com/article/166-how-to-provide-access-to-your-squarespace-website'
  },
  {
    platform: 'BigCommerce',
    articleID: '5d77a1db04286364bc8eed61',
    articleURL:
      'https://help.elfsight.com/article/147-how-to-provide-access-to-your-bigcommerce-website'
  },
  {
    platform: 'Wix',
    articleID: '5d8361ff04286364bc8f4f1f',
    articleURL:
      'https://help.elfsight.com/article/157-how-to-provide-access-to-your-wix-website'
  },
  {
    platform: 'Google Sites',
    articleID: '5f328a742c7d3a352e909ee0',
    articleURL:
      'https://help.elfsight.com/article/747-how-to-provide-access-to-your-google-sites-website'
  },
  {
    platform: 'Weebly',
    articleID: '5d83496d04286364bc8f4dc3',
    articleURL:
      'https://help.elfsight.com/article/156-how-to-provide-access-to-your-weebly-website'
  },
  {
    platform: 'Blogger',
    articleID: '5d95e9602c7d3a7e9ae2048b',
    articleURL:
      'https://help.elfsight.com/article/184-how-to-provide-access-to-your-blogger-website'
  },
  {
    platform: 'Carrd',
    articleID: '6156f86b2b380503dfdf69f4',
    articleURL:
      'https://help.elfsight.com/article/997-how-to-provide-access-to-carrd-website'
  },
  {
    platform: 'Duda',
    articleID: '5d82022d2c7d3a7e9ae14b9c',
    articleURL:
      'https://help.elfsight.com/article/153-how-to-provide-access-to-your-duda-website'
  },
  {
    platform: 'Ecwid',
    articleID: '60dc486005ff892e6bc2c2ff',
    articleURL:
      'https://help.elfsight.com/article/889-how-to-provide-access-to-your-ecwid-website'
  },
  {
    platform: 'GoDaddy',
    articleID: '5f7d82ad46e0fb001798a6c1',
    articleURL:
      'https://help.elfsight.com/article/787-how-to-provide-access-to-your-godaddy-website'
  },
  {
    platform: 'HubSpot',
    articleID: '6142680112c07c18afdd46c6',
    articleURL:
      'https://help.elfsight.com/article/971-how-to-provide-access-to-your-hubspot-website'
  },
  {
    platform: 'Jumpseller',
    articleID: '5dfb98fb04286364bc930f66',
    articleURL:
      'https://help.elfsight.com/article/337-how-to-provide-access-to-your-jumpseller-website'
  },
  {
    platform: 'Laravel',
    articleID: '615572f22b380503dfdf627f',
    articleURL:
      'https://help.elfsight.com/article/993-how-to-provide-access-to-your-laravel-website'
  },
  {
    platform: 'Mobirise',
    articleID: '633fe6930f10306a37c0a51a',
    articleURL:
      'https://help.elfsight.com/article/1137-how-to-provide-access-to-your-mobirise-website'
  },
  {
    platform: 'OpenCart',
    articleID: '5d94af3b04286364bc8fd978',
    articleURL:
      'https://help.elfsight.com/article/179-how-to-provide-access-to-your-opencart-website'
  },
  {
    platform: 'Prestashop',
    articleID: '611f896a6ffe270af2a9ab27',
    articleURL:
      'https://help.elfsight.com/article/937-how-to-provide-access-to-prestashop-website'
  },
  {
    platform: 'Webnode',
    articleID: '5d947aed2c7d3a7e9ae1e92d',
    articleURL:
      'https://help.elfsight.com/article/176-how-to-provide-access-to-your-webnode-website'
  }
];
