import { useContext } from 'react';
import styled from 'styled-components';
import { Link, TabsContext } from '@elfsight-universe/ui-common';
import { PlanTab } from '@modules/upgrade';
import {
  FeatureTooltip,
  FeatureTooltipProps
} from '../../../components/feature-tooltip';

interface WidgetsTooltipProps {
  widgetsLimit: number;
  isPackPlan?: boolean;
  appName?: string;
}

export function WidgetsLimitFeatureTooltip({
  widgetsLimit,
  isPackPlan,
  appName,
  children,
  ...forwardingProps
}: Omit<FeatureTooltipProps, 'content'> & WidgetsTooltipProps) {
  const tabsContext = useContext(TabsContext);
  const switchToPacks = () => tabsContext?.setActiveTab(PlanTab.PACKS);

  function renderContent() {
    if (isPackPlan) {
      return (
        <>
          Pack subscription allows you to create {widgetsLimit}&nbsp;widgets
          within each app ({widgetsLimit}&nbsp;Google Reviews, {widgetsLimit}
          &nbsp;Instagram Feeds, etc).
        </>
      );
    }

    const widgetOrWidgetsLabel = widgetsLimit > 1 ? 'widgets' : 'widget';

    return (
      <>
        <P>
          {' '}
          {appName ?? 'Single app'} subscription will allow you to have up
          to&nbsp;
          {widgetsLimit}{' '}
          {appName
            ? `${appName} ${widgetOrWidgetsLabel}`
            : `${widgetOrWidgetsLabel} of the given app`}
          .
        </P>
        <P>
          In case you&apos;d like to use several apps, we suggest that you take
          a look at the{' '}
          <Link underline onClick={switchToPacks}>
            Pack plans
          </Link>
          .
        </P>
      </>
    );
  }

  return (
    <FeatureTooltip content={renderContent()} {...forwardingProps}>
      {children}
    </FeatureTooltip>
  );
}

const P = styled.p`
  margin: 0;

  &:not(:first-child) {
    margin-top: 1.5em;
  }
`;
