import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { beacon } from './beacon';
import { sendAnalyticsEvent, useBeaconIdentity } from '@api';
import { useUser } from '@modules/_app';

type BeaconSubscriptionsProps = {
  position: 'right' | 'left';
  hidden: boolean | undefined;
  id: string;
};

export function BeaconSubscriptions({
  position = 'right',
  hidden,
  id
}: BeaconSubscriptionsProps) {
  const { user } = useUser();
  const router = useRouter();
  const isInitialized = useRef(false);
  const { data: beaconIdentity } = useBeaconIdentity();

  useEffect(() => {
    if (user && beaconIdentity !== undefined) {
      const beaconData = {
        name:
          user.lastName || user.firstName
            ? `${user.firstName ?? ''} ${user.lastName ?? ''}`
            : undefined,
        email: user.email
      };
      beacon.identify({
        ...beaconData,
        avatar: user.profilePictureURL ?? undefined,
        signature: beaconIdentity.signature
      });
      beacon.prefill({
        ...beaconData,
        subscriptionPlan: beaconIdentity.subscriptionPlan
      });
    }
  }, [user, beaconIdentity]);

  useEffect(() => {
    window.Beacon('config', {
      display: {
        position
      }
    });
  }, [position]);

  useEffect(() => {
    if (hidden || isInitialized.current) {
      window.Beacon('destroy');
      isInitialized.current = false;
      return;
    }

    requestAnimationFrame(() => {
      if (!hidden) {
        window.Beacon('init', id);
        isInitialized.current = true;
      }
    });
  }, [id, hidden]);

  useEffect(() => {
    const handleRouteChange = () => {
      beacon.event('page-viewed', {
        url: document.location.href,
        title: document.title
      });
      requestAnimationFrame(() => beacon.suggest());
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    const handleOpen = () => sendAnalyticsBeaconEvent('open');
    const handleClose = () => sendAnalyticsBeaconEvent('close');
    const handleArticleViewed = ({ id: article_id }: { id: string }) =>
      sendAnalyticsBeaconEvent('article_viewed', { article_id });
    const handleChatStarted = ({
      name,
      email
    }: {
      name: string;
      email: string;
    }) =>
      sendAnalyticsBeaconEvent('chat_started', {
        name,
        email
      });
    const handleEmailSent = ({
      name,
      email
    }: {
      name: string;
      email: string;
      text: string;
    }) =>
      sendAnalyticsBeaconEvent('email_sent', {
        name,
        email
      });
    const handleMessageClicked = ({ id: message_id }: { id: string }) =>
      sendAnalyticsBeaconEvent('message_clicked', {
        message_id
      });
    const handleMessageClosed = ({ id: message_id }: { id: string }) =>
      sendAnalyticsBeaconEvent('message_closed', {
        message_id
      });
    const handleMessageTriggered = ({ id: message_id }: { id: string }) =>
      sendAnalyticsBeaconEvent('message_triggered', {
        message_id
      });
    const handleSearch = ({ query: search_term }: { query: string }) =>
      sendAnalyticsBeaconEvent('search', {
        search_term
      });

    beacon.on('open', handleOpen);
    beacon.on('close', handleClose);
    beacon.on('article-viewed', handleArticleViewed);
    beacon.on('chat-started', handleChatStarted);
    beacon.on('email-sent', handleEmailSent);
    beacon.on('message-clicked', handleMessageClicked);
    beacon.on('message-closed', handleMessageClosed);
    beacon.on('message-triggered', handleMessageTriggered);
    beacon.on('search', handleSearch);

    return () => {
      beacon.off('open', handleOpen);
      beacon.off('close', handleClose);
      beacon.off('article-viewed', handleArticleViewed);
      beacon.off('chat-started', handleChatStarted);
      beacon.off('email-sent', handleEmailSent);
      beacon.off('message-clicked', handleMessageClicked);
      beacon.off('message-closed', handleMessageClosed);
      beacon.off('message-triggered', handleMessageTriggered);
      beacon.off('search', handleSearch);
    };
  }, []);

  return null;
}

const sendAnalyticsBeaconEvent = (
  action: string,
  params: Record<string, unknown> = {}
) =>
  sendAnalyticsEvent({
    name: 'beacon_action',
    params: { action, ...params }
  });
