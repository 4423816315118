import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  UPDATE_WIDGET_NAME_PATH,
  UpdateWidgetNameRequest,
  UpdateWidgetNameResponseError
} from '@elfsight-universe/service-core-contracts/widget/update-widget-name';
import { client } from '../client';

export function useUpdateWidgetNameMutation(
  widget: {
    pid: string;
    name?: string | null;
  },
  options: Pick<
    UseMutationOptions<
      Omit<UpdateWidgetNameRequest, 'pid'> | undefined,
      UpdateWidgetNameResponseError,
      Omit<UpdateWidgetNameRequest, 'pid'>
    >,
    'onError' | 'onSuccess'
  > = {}
) {
  return useMutation<
    Omit<UpdateWidgetNameRequest, 'pid'> | undefined,
    UpdateWidgetNameResponseError,
    Omit<UpdateWidgetNameRequest, 'pid'>
  >(async (data) => {
    if (data.name === widget.name) return undefined;

    await client.post(UPDATE_WIDGET_NAME_PATH, {
      json: { pid: widget.pid, ...data }
    });

    return data;
  }, options);
}
