import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { AutosizeTextarea } from '@elfsight-universe/ui-common';
import { CreateInstallationRequestAppealRequest } from '@elfsight-universe/service-core-contracts/appeal';
import { SecurityResponsibility } from './security-responsibility';

export function WithoutCollaboratorBlock({ ...forwardingProps }) {
  const {
    register,
    formState: { errors },
    clearErrors
  } = useFormContext<CreateInstallationRequestAppealRequest>();

  return (
    <Container {...forwardingProps}>
      <div>
        <AutosizeTextarea
          {...register('accessDetails', {
            onChange: () => {
              clearErrors('accessDetails');
            }
          })}
          error={errors?.accessDetails?.message}
          label="Website Access Details *"
          captionBottom="Please enter the necessary access details (username and password, or any specific instructions for access)."
        />
      </div>
      <SecurityResponsibility />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
