import { ReactText, useRef } from 'react';
import useCopyClipboard from 'react-use-clipboard';
import { Popover } from 'react-tiny-popover';
import styled, { css } from 'styled-components';
import { Copy16 } from '@elfsight/icons';
import { Button } from './buttons';
import { getColorWithAlpha } from '../utils';
import { useTheme } from '../theme';

export type CopiedCodeProps = {
  children: ReactText;
  color: string;
  textColor: string;
  buttonText?: string;
  copiedText?: string;
  disabled?: boolean;
  onCopyClick?: () => void;
  onCodeDoubleClick?: () => void;
};

export function CopiedCode({
  children,
  color,
  textColor,
  buttonText = 'Copy',
  copiedText = 'Copied',
  disabled
}: CopiedCodeProps) {
  const codeRef = useRef<HTMLElement>(null);
  const [isCopied, copyTextToClipboard] = useCopyClipboard(
    children.toString(),
    {
      successDuration: 1000
    }
  );

  const { zIndex: themeZIndex } = useTheme();

  const handleDoubleClick = () => {
    if (!codeRef.current) return;

    const range = document.createRange();
    range.selectNodeContents(codeRef.current);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  return (
    <Container _color={color} _disabled={disabled}>
      {!disabled && (
        <FloatingButton
          leftIcon={Copy16}
          backgroundColor={color}
          onClick={copyTextToClipboard}
          small
          height={28}
          disabled={disabled}
        >
          {buttonText}
        </FloatingButton>
      )}

      <Popover
        isOpen={isCopied}
        positions={['top']}
        containerStyle={{
          zIndex: themeZIndex.popoverDefault.toString()
        }}
        padding={4}
        content={<Copied>{copiedText}</Copied>}
      >
        <Pre onDoubleClick={handleDoubleClick}>
          <Code _textColor={textColor} ref={codeRef}>
            {children}
          </Code>
        </Pre>
      </Popover>
    </Container>
  );
}

const FloatingButton = styled(Button)`
  position: absolute;
  right: 4px;
  top: 4px;
`;

const Container = styled.div<{
  _color: string;
  _disabled?: boolean;
}>`
  position: relative;
  border-style: solid;
  border-width: 1px;
  transition: all 0.2s;
  border-radius: 4px;

  ${({ _disabled }) =>
    _disabled &&
    css`
      user-select: none;
      mix-blend-mode: luminosity;
      opacity: 0.5;
    `}

  ${({ _color }) => css`
    background-color: ${getColorWithAlpha(_color, 0.05)};
    border-color: ${getColorWithAlpha(_color, 0.5)};
  `}
`;

const Pre = styled.pre`
  padding: 16px;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Code = styled.code<{
  _textColor: string;
}>`
  ${({ theme }) => theme.font.base};
  ${({ theme }) => theme.font.caption};
  color: ${({ _textColor }) => _textColor};
`;

const Copied = styled.div`
  ${({ theme }) => theme.font.caption};
  padding: 6px 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;
