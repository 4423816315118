import { toast } from 'react-hot-toast';
import {
  useCountPublicAppsQuery,
  useDoneOnboardingActionMutation,
  useEmailResendConfirmMutation,
  useLastUsedInstalledAppQuery,
  useWidgetsListQuery
} from '@api';
import { useIsMobile } from '@modules/_app';
import { useHeader } from '@modules/_header';
import { createCommunityAuthLink } from '@utils';
import { beacon } from '../beacon';
import { Button, ButtonProps } from '@elfsight-universe/ui-common';
import { OnboardingActionType } from '@elfsight-universe/service-core-contracts/onboarding/onboarding-action-type.enum';
import { OnboardingItem, OnboardingItemProps } from './onboarding-item';

export const ITEMS = [
  {
    action: OnboardingActionType.VERIFY_EMAIL,
    component: VerifyEmailOnboardingItem
  },
  {
    action: OnboardingActionType.CREATE_WIDGET,
    component: CreateWidgetOnboardingItem
  },
  {
    action: OnboardingActionType.INSTALL_WIDGET,
    component: EmbedWidgetOnboardingItem
  },
  {
    action: OnboardingActionType.CHOOSE_OCCUPATION,
    component: ChooseOccupationOnboardingItem
  },
  {
    action: OnboardingActionType.EXPLORE_COMMUNITY,
    component: ExploreCommunityOnboardingItem
  },
  {
    action: OnboardingActionType.DISCOVER_APPS,
    component: DiscoverAppsOnboardingItem
  }
];

export type PredefinedOnboardingItemProps = {
  type: OnboardingActionType;
} & Omit<OnboardingItemProps, 'title' | 'actions' | 'children'>;

export function CreateWidgetOnboardingItem({
  type,
  ...forwardingProps
}: PredefinedOnboardingItemProps) {
  const { data } = useCountPublicAppsQuery();
  const appsCount = data?.count;

  return (
    <OnboardingItem
      title="Create your first widget"
      actions={
        <ActionButton href="/catalog?create=true">Choose an App</ActionButton>
      }
      {...forwardingProps}
    >
      Choose one of {appsCount} apps in our catalog, then pick a template and
      configure your first widget.
    </OnboardingItem>
  );
}

export function DiscoverAppsOnboardingItem({
  type,
  ...forwardingProps
}: PredefinedOnboardingItemProps) {
  const { data } = useCountPublicAppsQuery();
  const appsCount = data?.count;

  return (
    <OnboardingItem
      title="Discover more apps"
      actions={
        <ActionButton href="/catalog">Explore Apps Catalog</ActionButton>
      }
      {...forwardingProps}
    >
      We offer {appsCount} coding-free apps — social feeds, reviews, chats,
      popup and form builders, audio players, video and photo galleries, maps
      and a lot of other useful tools.
    </OnboardingItem>
  );
}

export function EmbedWidgetOnboardingItem({
  type,
  ...forwardingProps
}: PredefinedOnboardingItemProps) {
  const { toggleOnboardingOpen, toggleMobileNav } = useHeader();
  const { data: lastUsedInstalledApp } = useLastUsedInstalledAppQuery();
  const alias = lastUsedInstalledApp?.app?.alias || '';

  const dto = {
    page: 1,
    itemsPerPage: 10,
    appAlias: alias
  };

  const { data: widgets } = useWidgetsListQuery(dto, {
    enabled: Boolean(lastUsedInstalledApp?.app)
  });

  const firstWidgetPid = widgets?.payload[0]?.pid;
  const href = firstWidgetPid ? `/apps/${alias}?install=${firstWidgetPid}` : '';

  const closeOnboarding = () => {
    toggleMobileNav(false);
    toggleOnboardingOpen(false);
  };

  return (
    <OnboardingItem
      title="Install the widget on your website"
      actions={
        <>
          <ActionButton
            disabled={!firstWidgetPid}
            href={href}
            onClick={closeOnboarding}
          >
            Get Embed Code
          </ActionButton>
          <AdditionalButton
            onClick={() => {
              beacon.openChat();
              closeOnboarding();
            }}
          >
            Request Help
          </AdditionalButton>
          <SkipButton type={type} />
        </>
      }
      {...forwardingProps}
    >
      Copy the widget’s embed code and paste it to your website. Also you can
      ask our support team to embed the widget for you for free.
    </OnboardingItem>
  );
}

export function ChooseOccupationOnboardingItem({
  type,
  ...forwardingProps
}: PredefinedOnboardingItemProps) {
  const { toggleOnboardingOpen, toggleMobileNav } = useHeader();
  const closeOnboarding = () => {
    toggleMobileNav(false);
    toggleOnboardingOpen(false);
  };

  return (
    <OnboardingItem
      title="Tell us about your professional role"
      actions={
        <ActionButton href="/account/profile" onClick={closeOnboarding}>
          Choose Professional Role
        </ActionButton>
      }
      {...forwardingProps}
    >
      To make your Elfsight experience even more amazing, we would like you to
      tell us a bit about yourself.
    </OnboardingItem>
  );
}

export function ExploreCommunityOnboardingItem({
  type,
  ...forwardingProps
}: PredefinedOnboardingItemProps) {
  const { mutate: done } = useDoneOnboardingActionMutation();

  return (
    <OnboardingItem
      title="Explore the community"
      actions={
        <ActionButton
          href={createCommunityAuthLink('https://community.elfsight.com')}
          target="_blank"
          onClick={() => done({ type })}
        >
          Visit Community Forum
        </ActionButton>
      }
      {...forwardingProps}
    >
      Join our community to connect with your peers and other experts, discuss
      Elfsight widgets usage, stay up to date with the Elfsight latest news, and
      much more!
    </OnboardingItem>
  );
}

export function VerifyEmailOnboardingItem({
  type,
  ...forwardingProps
}: PredefinedOnboardingItemProps) {
  const { mutate: resendConfirmation, isLoading: isResendConfirmationLoading } =
    useEmailResendConfirmMutation({
      onSuccess: () => {
        toast.success('Follow the link in the confirmation email we sent.');
      }
    });

  return (
    <OnboardingItem
      title="Verify your account to secure it"
      actions={
        <ActionButton
          isLoading={isResendConfirmationLoading}
          onClick={() => resendConfirmation()}
        >
          Resend Confirmation Email
        </ActionButton>
      }
      {...forwardingProps}
    >
      Check your inbox and click the button to confirm your email address.
    </OnboardingItem>
  );
}

const ActionButton = (props: ButtonProps) => (
  <Button fullWidth center small variation="accentSecondary" {...props} />
);

const AdditionalButton = (props: ButtonProps) => (
  <Button fullWidth center small variation="secondary" {...props} />
);

const SkipButton = ({ type }: { type: OnboardingActionType }) => {
  const isMobile = useIsMobile();
  const { mutate: done } = useDoneOnboardingActionMutation();

  return (
    <Button
      fullWidth={isMobile}
      center
      small
      variation="text"
      disableShrink
      padding={8}
      onClick={() => done({ type })}
    >
      Skip
    </Button>
  );
};
