import { CreateFeatureOptions, FeatureResolver } from '../feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';

export const aiReviewsTranslationFeatureResolver: FeatureResolver = ({
  isPackPlan,
  isLitePlan,
  isBasicPlan,
  appAlias
}: CreateFeatureOptions) => {
  if (isPackPlan || !appAlias) {
    return;
  }

  if (!appAlias.endsWith('-reviews')) {
    return;
  }

  return {
    text: (
      <FeatureTooltip
        content={
          <>
            AI Reviews Translation automatically translates customer reviews
            into the default language set in your widget settings, ensuring
            consistent display across your site. Users can switch between the
            original and translated text, enhancing their experience and
            providing access to genuine feedback.
          </>
        }
      >
        <>AI Review Translation</>
      </FeatureTooltip>
    ),
    excluded: isLitePlan || isBasicPlan
  };
};
