import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  CHANGE_WIDGET_VISIBILITY_PATH,
  ChangeWidgetVisibilityRequest,
  ChangeWidgetVisibilityResponse,
  ChangeWidgetVisibilityResponseError
} from '@elfsight-universe/service-core-contracts/widget/change-widget-visibility';
import { client } from '../client';

export function useChangeWidgetVisibilityMutation(
  pid: string,
  options: Pick<
    UseMutationOptions<
      ChangeWidgetVisibilityResponse,
      ChangeWidgetVisibilityResponseError,
      Omit<ChangeWidgetVisibilityRequest, 'pid'>
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    ChangeWidgetVisibilityResponse,
    ChangeWidgetVisibilityResponseError,
    Omit<ChangeWidgetVisibilityRequest, 'pid'>
  >(
    ({ visibility }) =>
      client
        .post(CHANGE_WIDGET_VISIBILITY_PATH, { json: { pid, visibility } })
        .json<ChangeWidgetVisibilityResponse>(),
    options
  );
}
