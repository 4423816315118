import { CreateFeatureOptions, FeatureResolver } from '../feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';

export const advancedCustomizationFeatureResolver: FeatureResolver = ({
  isLitePlan
}: CreateFeatureOptions) => ({
  text: (
    <FeatureTooltip
      content={
        <>
          Advanced Customizations refers to our support team&apos;s ability to
          assist with tailor-made widget adjustments using CSS code. We strive
          to accommodate your customization needs wherever possible. However,
          please note that some customizations may not be feasible due to
          technical constraints.
        </>
      }
    >
      <>Advanced customizations</>
    </FeatureTooltip>
  ),
  excluded: isLitePlan
});
