import Router from 'next/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SIGN_OUT_PATH } from '@elfsight-universe/service-core-contracts/identity-and-access/sign-out';
import { useClientMessage } from '@modules/_app';
import { beacon } from '@modules/beacon';
import { useCurrentAccountPid } from '@modules/_app/utils/use-current-account-pid';
import { client } from '../client';

const SIGN_IN_URL = '/login';

export function useSignOutMutation() {
  const queryClient = useQueryClient();
  const clientMessage = useClientMessage();
  const { clear: clearCurrentAccountPid } = useCurrentAccountPid();

  return useMutation(() => client.post(SIGN_OUT_PATH), {
    onSuccess: () => {
      clearCurrentAccountPid();
      queryClient.clear();
      Router.replace(SIGN_IN_URL);
      clientMessage.close();
      beacon.logout();
    }
  });
}
