import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const SWITCH_WIDGET_TO_DRAFT_PATH = 'widget/switch-widget-to-draft';

export class SwitchWidgetToDraftRequest {
  @IsUUID()
  pid: string;
}

export type SwitchWidgetToDraftResponseError =
  WidgetError.WIDGET_DOES_NOT_EXIST;
