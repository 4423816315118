import { useState } from 'react';
import styled, { css } from 'styled-components';
import { UserAvatar, Icon, Popover } from '@elfsight-universe/ui-common';
import { AngleDown16 } from '@elfsight/icons';
import { useUser } from '../_app';
import { HeaderUserPopover } from './header-user-popover';

export function HeaderUser() {
  const { user } = useUser();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  if (!user || !user.effectiveAccount) return null;

  return (
    <Popover
      isOpen={isPopoverOpen}
      onClickOutside={() => setPopoverOpen(false)}
      positions={['bottom']}
      align="end"
      content={
        <HeaderUserPopover onRequestClose={() => setPopoverOpen(false)} />
      }
    >
      <Container
        _isOpen={isPopoverOpen}
        onClick={() => setPopoverOpen(!isPopoverOpen)}
      >
        <AvatarContainer>
          <UserAvatar
            size={32}
            src={user.effectiveAccount.avatar}
            placeholderArgs={user.effectiveAccount}
          />

          {user.effectiveAccount.pid !== user.owningAccountPid && (
            <AccountOwner>
              <UserAvatar
                size={16}
                src={user.profilePictureURL}
                placeholderArgs={user}
              />
            </AccountOwner>
          )}
        </AvatarContainer>

        <PopoverToggle size={16} fill="currentColor" component={AngleDown16} />
      </Container>
    </Popover>
  );
}

const PopoverToggle = styled(Icon)`
  margin-left: 8px;
  transition: transform 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
`;

const Container = styled.div<{ _isOpen: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: color 0.2s, background 0.2s;
  background: ${({ theme }) => theme.colors.transparent};
  color: ${({ theme }) => theme.colors.gray70};
  padding: 2px 4px;

  ${({ _isOpen, theme }) =>
    _isOpen
      ? css`
          background: ${theme.colors.gray10};
          color: ${theme.colors.black};
          ${PopoverToggle} {
            transform: rotateX(180deg);
          }
        `
      : css`
          :hover {
            background: ${theme.colors.gray5};
            color: ${theme.colors.black};
          }
        `}
`;

const AvatarContainer = styled.div`
  position: relative;
`;

const AccountOwner = styled.div`
  border-radius: 16px;
  position: absolute;
  right -8px;
  bottom: -2px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.white};
`;
