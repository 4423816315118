import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  DELETE_WIDGET_PATH,
  DeleteWidgetResponseError
} from '@elfsight-universe/service-core-contracts/widget/delete-widget';
import { client } from '../client';

export function useDeleteWidgetMutation(
  pid: string,
  options: Pick<
    UseMutationOptions<Response, DeleteWidgetResponseError>,
    'onError' | 'onSuccess' | 'onMutate' | 'onSettled'
  >
) {
  return useMutation<Response, DeleteWidgetResponseError>(
    () => client.post(DELETE_WIDGET_PATH, { json: { pid } }),
    options
  );
}
