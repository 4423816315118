import { useQuery } from '@tanstack/react-query';
import { client } from '../client';
import { useUser } from '@modules/_app';
import {
  GET_ALL_BADGES_PATH,
  GetAllBadgesResponse
} from '@elfsight-universe/service-core-contracts/widget/get-all-badges';

export function useAllBadgesQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetAllBadgesResponse>(
    [GET_ALL_BADGES_PATH],
    () => client.get(GET_ALL_BADGES_PATH).json<GetAllBadgesResponse>(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isAuthenticated
    }
  );
}
