import { forwardRef, AnchorHTMLAttributes } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

export type NextLinkComposedProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> &
  Omit<NextLinkProps, 'as' | 'href'> & {
    linkAs?: NextLinkProps['as'];
    href?: NextLinkProps['href'];
  };

export const NextLinkComposed = forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>(function NextLinkComposed(
  {
    prefetch,
    replace,
    shallow,
    scroll,
    locale,
    linkAs,
    href,
    ...forwardingProps
  },
  ref
) {
  if (!href) {
    return <a ref={ref} {...forwardingProps} />;
  }

  return (
    <NextLink
      prefetch={prefetch}
      replace={replace}
      shallow={shallow}
      scroll={scroll}
      locale={locale}
      href={href}
      as={linkAs}
      passHref
    >
      <a ref={ref} {...forwardingProps} />
    </NextLink>
  );
});
