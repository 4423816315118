import { usePackPlansQuery } from '@api';
import {
  PricingTableAllAppsPackList,
  PricingTableColorTheme
} from '@modules/upgrade/pricing-table';
import { useUpgradeContext } from '@modules/upgrade/upgrade-context';
import { Plans, PlansProps } from './plans';
import { formatPlans } from '../utils';

export function PlansPack(
  forwardingProps: Omit<PlansProps, 'isLoading' | 'plans' | 'onPlanSelect'>
) {
  const { data: context } = useUpgradeContext();
  const { data = { payload: [] }, isLoading } = usePackPlansQuery(
    context?.app?.alias
  );

  const plans = formatPlans(data.payload, {
    effectivePlanPid: context?.effectivePlanPid,
    pricingTableColorTheme: PricingTableColorTheme.BLACK
  });

  return (
    <>
      <PricingTableAllAppsPackList />
      <Plans
        isPack
        isLoading={isLoading}
        plans={plans}
        colorTheme={PricingTableColorTheme.BLACK}
        {...forwardingProps}
      />
    </>
  );
}
