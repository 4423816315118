import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { ScheduledToastType, useScheduledToast } from '@utils';
import { useUser } from './app-user-provider';
import { usePageContext } from './app-page-provider';

export const AppAccountToastProviderContext = createContext<null>(null);

export function AppAccountToastProvider({ children }: PropsWithChildren) {
  const { user } = useUser();
  const { guest } = usePageContext();
  const {
    trigger: triggerToast,
    isScheduled: isToastScheduled,
    data: scheduledToastData
  } = useScheduledToast({
    type: ScheduledToastType.ACCOUNT_SWITCHED
  });

  useEffect(() => {
    const shouldShowToast = user?.effectiveAccount && !guest;

    if (!isToastScheduled || !shouldShowToast) {
      return;
    }

    const showToast = () => {
      if (scheduledToastData?.message) {
        triggerToast();
        return;
      }

      const { email, firstName, lastName } = user.effectiveAccount;
      const name =
        firstName && lastName
          ? `${firstName} ${lastName}`
          : firstName || lastName || email;

      triggerToast(`Switched to ${name} Workspace`);
    };

    showToast();
  }, [user, guest, isToastScheduled, scheduledToastData, triggerToast]);

  return (
    <AppAccountToastProviderContext.Provider value={null}>
      {children}
    </AppAccountToastProviderContext.Provider>
  );
}

export function useAppAccountToastProviderContext() {
  const context = useContext(AppAccountToastProviderContext);

  if (context === null) {
    throw new Error(
      '`useAppAccountToastProviderContext` must be nested inside an `AppAccountToastProvider`.'
    );
  }

  return context;
}
