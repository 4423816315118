import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import {
  useCommitUsageInstalledAppMutation,
  useInstalledAppsWithViewsIndicatorsQuery,
  useLastUsedInstalledAppQuery
} from '@api';
import { useEvents } from '@modules/_event-bus';
import { useSetLastUsedInstalledAppQueryData } from './utils';

type BackToAppsPageProps = {
  appAlias?: string;
  historyReplace?: boolean;
  fallbackRoute?: string;
  query?: ParsedUrlQueryInput;
};

interface ICurrentAppAliasContext {
  currentAppAlias: string;
  setCurrentAppAlias: (appAlias: string) => void;
  isCurrentAppAliasLoading: boolean;
  isCurrentAppAliasFetched: boolean;
  myAppsNavLink: string;
  backToAppsPage: (props?: BackToAppsPageProps) => void;
}

export const AppCurrentAppAliasContext =
  createContext<ICurrentAppAliasContext | null>(null);

export function AppCurrentAppAliasProvider({ children }: PropsWithChildren) {
  const { query, replace, push } = useRouter();
  const setLastUsedInstalledAppQueryData =
    useSetLastUsedInstalledAppQueryData();
  const {
    data: lastUsedInstalledAppData,
    isLoading: isLastUsedInstalledAppLoading,
    isFetched: isLastUsedInstalledAppFetched
  } = useLastUsedInstalledAppQuery();
  const currentAppAlias = lastUsedInstalledAppData?.app?.alias ?? '';

  const { mutate: commitLastUsageApp } = useCommitUsageInstalledAppMutation({
    onMutate: ({ appAlias }) => {
      setLastUsedInstalledAppQueryData(appAlias);
    }
  });

  const { data: installedApps = { payload: [] } } =
    useInstalledAppsWithViewsIndicatorsQuery();

  const [myAppsNavLink, setMyAppsNavLink] = useState('');

  const queryAppAlias = query.alias as string | undefined;
  const finalCurrentAppAlias = queryAppAlias || currentAppAlias;

  const setCurrentAppAlias = (alias: string) => {
    commitLastUsageApp({ appAlias: alias });
  };

  useEvents(['LastUsedInstalledAppUpdated'], ({ payload }) => {
    setLastUsedInstalledAppQueryData(payload.alias as string);
  });

  useEffect(() => {
    const link =
      installedApps.payload.length === 0 ? '/apps' : `/apps/${currentAppAlias}`;
    setMyAppsNavLink(link);
  }, [currentAppAlias, installedApps]);

  const backToAppsPage = ({
    appAlias = finalCurrentAppAlias,
    historyReplace,
    fallbackRoute = '/',
    query = {}
  }: BackToAppsPageProps = {}) => {
    if (!appAlias) {
      return replace(fallbackRoute);
    }

    const filteredQuery = omitBy(query, isNil);
    const method = historyReplace ? replace : push;
    return method({
      pathname: `/apps/${appAlias}`,
      query: filteredQuery
    });
  };

  return (
    <AppCurrentAppAliasContext.Provider
      value={{
        currentAppAlias: finalCurrentAppAlias,
        setCurrentAppAlias,
        isCurrentAppAliasLoading: isLastUsedInstalledAppLoading,
        isCurrentAppAliasFetched: isLastUsedInstalledAppFetched,
        myAppsNavLink,
        backToAppsPage
      }}
    >
      {children}
    </AppCurrentAppAliasContext.Provider>
  );
}

export function useAppCurrentAppAliasContext() {
  const context = useContext(AppCurrentAppAliasContext);

  if (context === null) {
    throw new Error(
      '`useAppCurrentAppAliasContext` must be nested inside an `AppCurrentAppAliasProvider`.'
    );
  }

  return context;
}
