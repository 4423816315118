import flatten from 'lodash/flatten';
import {
  CreateFeatureOptions,
  Feature
} from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import { PricingTableColorTheme } from '@modules/upgrade/pricing-table';
import { basicFeatureResolvers } from '@modules/upgrade/pricing-table/pricing-table-features/basic-feature-resolvers';
import { enterpriseFeatureResolvers } from '@modules/upgrade/pricing-table/pricing-table-features/enterprise-feature-resolvers';

export function createPricingTableFeatures(
  options: CreateFeatureOptions,
  colorTheme: PricingTableColorTheme = PricingTableColorTheme.WHITE
) {
  const featureResolvers = options.isEnterprisePlan
    ? enterpriseFeatureResolvers
    : basicFeatureResolvers;

  const features = featureResolvers
    .map((resolver) => resolver(options, colorTheme))
    .filter((feature): feature is Feature | Feature[] => Boolean(feature));

  return flatten(features);
}
