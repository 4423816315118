import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';
import confetti from 'canvas-confetti';
import { ElfsightGlyph } from '@elfsight/icons';
import {
  AppIconWithContainer,
  Button,
  H1,
  Icon,
  Loader,
  Modal,
  ModalProps,
  Typography,
  MOBILE
} from '@elfsight-universe/ui-common';
import {
  GetSubscriptionAppInfoResponse,
  PlanType
} from '@elfsight-universe/service-core-contracts/billing';
import { useDashboardContext } from '@modules/dashboard/dashboard-context';
import {
  SECOND_APP_DEAL_ALIAS,
  SWITCH_TO_ANNUAL_DEAL_ALIAS,
  SWITCH_TO_PACK_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';
import { getDealURL } from '@modules/deals/utils';
import { useInstantExtensionCampaignContext } from '@modules/instant-extension-campaign/context/instant-extension-campaign-context';
import { useCongratulationsSuitableDeal } from './utils/use-congratulations-suitable-deal';
import { DealButton } from '../components/deal-button';
import { getHumanizedPlanGradeName } from './utils/get-humanized-plan-grade-name';

const CONFETTI_CONFIG = {
  particleCount: 200,
  spread: 100,
  origin: { y: 0.4 },
  colors: ['#fbda61', '#ff5acd'],
  startVelocity: 35,
  disableForReducedMotion: true
};

const ENSURE_DEAL_TIMEOUT = 2500;

export const APP_SUBSCRIPTION_SUCCESS_QUERY_PARAM = 'congratulations';
export const SECOND_APP_DEAL_PROMISING_QUERY_PARAM =
  'second-app-deal-promising';

type AppSubscriptionSuccessModalProps = Omit<
  ModalProps,
  'title' | 'actions'
> & { secondAppDealPromising?: boolean };

export function AppSubscriptionSuccessModal({
  isOpen,
  onRequestClose,
  secondAppDealPromising,
  ...forwardingProps
}: AppSubscriptionSuccessModalProps) {
  const { zIndex } = useTheme();
  const ensureDealTimeoutRef = useRef<NodeJS.Timeout>();
  const { currentAppInfo } = useDashboardContext();
  const [isSecondAppDealButtonLoading, setIsSecondAppDealButtonLoading] =
    useState(false);
  const [isDealButtonLoading, setDealButtonLoading] = useState(false);
  const { accountDeal, isReady: appInstantExtensionCampaignIsReady } =
    useInstantExtensionCampaignContext();

  let suitableDealsByPriority = [
    SWITCH_TO_ANNUAL_DEAL_ALIAS,
    SWITCH_TO_PACK_DEAL_ALIAS
  ];

  /**
   * Remove SECOND_APP_DEAL_ALIAS to avoid DealButton flickering
   */
  if (secondAppDealPromising) {
    suitableDealsByPriority = [
      SECOND_APP_DEAL_ALIAS,
      ...suitableDealsByPriority
    ];
  }

  const { suitableDeal, isLoading: isSuitableDealLoading } =
    useCongratulationsSuitableDeal({
      suitableDealsByPriority
    });

  const isDialogReady =
    !!currentAppInfo?.plan?.grade && appInstantExtensionCampaignIsReady;
  const dealURL = suitableDeal ? getDealURL(suitableDeal) : '/deals';

  /**
   * waiting secondAppCoupon if SECOND_APP_DEAL_PROMISING_QUERY_PARAM
   */

  useEffect(() => {
    if (secondAppDealPromising) {
      setIsSecondAppDealButtonLoading(true);
    }

    if (
      secondAppDealPromising &&
      suitableDeal?.deal?.alias === SECOND_APP_DEAL_ALIAS
    ) {
      setIsSecondAppDealButtonLoading(false);
    }
  }, [secondAppDealPromising, suitableDeal]);

  /**
   * wait an additional ENSURE_DEAL_TIMEOUT to ensure that the offer is valid
   */
  useEffect(() => {
    if (secondAppDealPromising) {
      return;
    }

    if (isDialogReady) {
      setDealButtonLoading(true);

      ensureDealTimeoutRef.current = setTimeout(() => {
        setDealButtonLoading(false);
      }, ENSURE_DEAL_TIMEOUT);
    }
  }, [secondAppDealPromising, suitableDeal, isDialogReady]);

  /**
   * fire confetti
   */
  useEffect(() => {
    if (isOpen && isDialogReady) {
      confetti({
        ...CONFETTI_CONFIG,
        zIndex: zIndex.subscriptionSuccessModal + 1
      });
    }
  }, [isOpen, isDialogReady]);

  const isAppInstantExtensionCampaignActive =
    appInstantExtensionCampaignIsReady && accountDeal;

  const shouldDisplayDealLoader =
    isSecondAppDealButtonLoading || isDealButtonLoading;

  const shouldDisplayDealButton =
    suitableDeal && !isSuitableDealLoading && !shouldDisplayDealLoader;

  if (!isDialogReady) {
    return null;
  }

  return (
    <Modal
      maxWidth={500}
      onRequestClose={onRequestClose}
      padding={[0, 0]}
      zIndex={zIndex.subscriptionSuccessModal}
      isOpen={isOpen}
      withClose
      {...forwardingProps}
    >
      <ContentContainer>
        <InnerWrapper>
          <IconWrapper>
            {currentAppInfo.plan.type === PlanType.ALL_APPS_PACK ? (
              <ElfsightLogoWrapper>
                <Icon component={ElfsightGlyph} size={35} fill="white" />
              </ElfsightLogoWrapper>
            ) : (
              <AppIconWithContainer
                icon={currentAppInfo.app.icon}
                size="extraLarge"
                backgroundColor={currentAppInfo.app.category.color}
                linearGradient=""
              />
            )}
          </IconWrapper>

          <Title>{getTitleText(currentAppInfo)}</Title>
          <Description variant="text">
            {getDescription(currentAppInfo)}
          </Description>

          {shouldDisplayDealLoader && !isAppInstantExtensionCampaignActive && (
            <DealButtonLoader spacing={4} />
          )}

          {shouldDisplayDealButton && !isAppInstantExtensionCampaignActive && (
            <DealButton
              href={dealURL}
              text={suitableDeal.deal.shortCaption || 'Claim deal'}
              expiredAt={suitableDeal.expiredAt}
            />
          )}

          <OkButton onClick={onRequestClose} variation="text">
            OK, Great!
          </OkButton>
        </InnerWrapper>
      </ContentContainer>
    </Modal>
  );
}

const getTitleText = (currentAppInfo: GetSubscriptionAppInfoResponse) => {
  const {
    plan: { type, grade },
    app: { name }
  } = currentAppInfo;
  const humanizedGrade = grade ? getHumanizedPlanGradeName(grade) : '';

  if (type === PlanType.ALL_APPS_PACK) {
    return `All Apps Pack plan is active`;
  }

  return `${name} was upgraded to ${humanizedGrade}`;
};

const getDescription = (currentAppInfo: GetSubscriptionAppInfoResponse) => {
  if (currentAppInfo.plan.type === PlanType.ALL_APPS_PACK) {
    return `It's time to explore all the widgets!`;
  }

  return 'Power up your website with the expanded capabilities!';
};

const bounceIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.65);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const ContentContainer = styled.div`
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 360px;
`;

const InnerWrapper = styled.div<{ $alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 60px 40px 20px;

  overflow-y: auto;

  ${({ $alignCenter }) =>
    $alignCenter &&
    css`
      padding: 0;
      margin: auto;
    `}

  ${MOBILE} {
    padding: 32px 20px 20px;
  }
`;

const IconWrapper = styled.div`
  animation: ${bounceIn} 500ms ease 100ms forwards;
  transform: scale(0.65);
  opacity: 0;
`;

const ElfsightLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #fa527a 0%, #f80741 172.5%);
  border-radius: 16px;
  width: 60px;
  height: 60px;
`;

const Title = styled(H1)`
  margin: 24px 0 12px;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
`;

const Description = styled(Typography)`
  text-align: center;
  margin-bottom: 40px;

  ${MOBILE} {
    margin-bottom: 24px;
  }
`;

const OkButton = styled(Button)`
  min-height: 32px;
`;

const DealButtonLoader = styled(Loader)`
  margin-bottom: 16px;
`;
