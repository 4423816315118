import {
  cloneElement,
  forwardRef,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  PopoverProps,
  PopoverPosition,
  Popover,
  PopoverState
} from 'react-tiny-popover';
import styled, { css } from 'styled-components';
import { useTheme } from '@elfsight-universe/ui-common';
import { slideInTranslateMixin } from './slide-in-translate-mixin';

const ARROW_SIZE = 4;
const OFFSET = 4;

export type TooltipProps = Omit<PopoverProps, 'content' | 'isOpen'> & {
  isForceOpen?: boolean;
  openDelay?: number;
  closeDelay?: number;
  width?: number;
  colorTheme?: 'white' | 'black';
  withArrow?: boolean;
  alignCenter?: boolean;
  preventOpen?: boolean;
  content: ReactNode;
  withForgivingHitArea?: boolean;
  forgivingTolerance?: number;
};

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(function _Tooltip(
  {
    isForceOpen,
    content,
    children,
    openDelay = 400,
    closeDelay = 0,
    colorTheme = 'black',
    withArrow = false,
    alignCenter,
    width,
    preventOpen: externalPreventOpen,
    withForgivingHitArea = false,
    forgivingTolerance = 10,
    align = 'center',
    ...forwardingProps
  }: TooltipProps,
  forwardingRef
) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [preventOpen, setPreventOpen] = useState(externalPreventOpen);
  const [isOpen, setOpen] = useState(false);

  const openTimeout = useRef<NodeJS.Timeout>();
  const closeTimeout = useRef<NodeJS.Timeout>();

  const { colors: themeColors, zIndex: themeZIndex } = useTheme();
  const colors = {
    black: {
      _backgroundColor: themeColors.tooltip,
      _textColor: themeColors.white
    },
    white: {
      _backgroundColor: themeColors.white,
      _textColor: themeColors.black
    }
  }[colorTheme];

  const clearTimeouts = () => {
    if (closeTimeout.current) clearTimeout(closeTimeout.current);
    if (openTimeout.current) clearTimeout(openTimeout.current);
  };

  useEffect(() => {
    if (preventOpen) {
      clearTimeouts();
      setOpen(false);
    }
  }, [preventOpen]);

  const handleOpen = () => {
    if (externalPreventOpen || preventOpen) return;

    clearTimeouts();

    openTimeout.current = setTimeout(() => {
      setOpen(true);
    }, openDelay);
  };

  const handleClose = () => {
    if (!externalPreventOpen) {
      setPreventOpen(false);
    }

    clearTimeouts();

    closeTimeout.current = setTimeout(() => {
      setOpen(false);
    }, closeDelay);
  };

  const contentHandlers = {
    onMouseOver: handleOpen,
    onMouseLeave: handleClose
  };

  const childrenHandlers = {
    onTouchStart: (e: MouseEvent<HTMLElement>) => {
      handleOpen();
      children.props.onTouchStart && children.props.onTouchStart(e);
    },
    onMouseOver: (e: MouseEvent<HTMLElement>) => {
      handleOpen();
      children.props.onMouseOver && children.props.onMouseOver(e);
    },
    onMouseLeave: (e: MouseEvent<HTMLElement>) => {
      handleClose();
      children.props.onMouseLeave && children.props.onMouseLeave(e);
    },
    onClick: (e: MouseEvent<HTMLElement>) => {
      setPreventOpen(true);
      children.props.onClick && children.props.onClick(e);
    }
  };

  if (!content) return children;

  return (
    <Popover
      isOpen={isForceOpen || isOpen}
      containerStyle={{
        zIndex: themeZIndex.tooltip.toString()
      }}
      positions={['top', 'bottom']}
      ref={forwardingRef}
      align={align}
      padding={OFFSET}
      boundaryInset={12}
      content={(popoverState) => {
        const { position, childRect, popoverRect } = popoverState;

        const arrowLeft =
          childRect.left - popoverRect.left + childRect.width / 2;
        const arrowTop = childRect.top - popoverRect.top + childRect.height / 2;

        const SVGHeight = OFFSET + childRect.height + popoverRect.height;
        const SVGWidth = Math.max(childRect.width, popoverRect.width);

        const svgPath = getForgivingHitAreaPath(
          popoverState,
          forgivingTolerance
        );

        return (
          <CSSTransition
            nodeRef={contentRef}
            classNames="slide-in"
            timeout={0}
            in
            appear
          >
            <Content
              _alignCenter={alignCenter}
              _withArrow={withArrow}
              _position={position}
              _arrowLeft={arrowLeft}
              _arrowTop={arrowTop}
              _childRect={childRect}
              _width={width}
              ref={contentRef}
              {...colors}
              {...contentHandlers}
            >
              {content}

              {withForgivingHitArea && svgPath && (
                <SVG width={SVGWidth} height={SVGHeight} _position={position}>
                  <Path d={svgPath} />
                </SVG>
              )}
            </Content>
          </CSSTransition>
        );
      }}
      {...forwardingProps}
    >
      {cloneElement(children, {
        ...children.props,
        ...childrenHandlers
      })}
    </Popover>
  );
});

const Content = styled.div<{
  _position?: PopoverPosition;
  _alignCenter?: boolean;
  _width?: number;
  _childRect: DOMRect;
  _arrowLeft: number;
  _arrowTop: number;
  _backgroundColor: string;
  _textColor: string;
  _withArrow: boolean;
}>`
  position: relative;
  padding: 8px 12px;
  border-radius: 4px;
  max-width: ${({ _width }) => (_width ? `${_width}px` : 'inherit')};
  text-align: ${({ _alignCenter }) => (_alignCenter ? 'center' : 'left')};
  background: ${({ _backgroundColor }) => _backgroundColor};
  color: ${({ _textColor }) => _textColor};
  ${({ theme }) => theme.font.caption};
  box-shadow: 0 3px 6px ${({ theme }) => theme.colors.gray10};

  ${slideInTranslateMixin(4)}

  ${({ _position, _backgroundColor, _withArrow, _arrowLeft }) =>
    _withArrow &&
    _position &&
    css`
      &::after {
        position: absolute;
        content: '';
        width: 0;

        ${
          {
            bottom: css`
            left: ${_arrowLeft}px;
            margin-left: -${ARROW_SIZE}px;
            top: -${ARROW_SIZE}px;
            border-left: ${ARROW_SIZE}px solid transparent;
            border-right: ${ARROW_SIZE}px solid transparent;
            border-bottom: ${ARROW_SIZE}px solid ${_backgroundColor};
          `,
            top: css`
            left: ${_arrowLeft}px;
            margin-left: -${ARROW_SIZE}px;
            bottom: -${ARROW_SIZE}px;
            border-left: ${ARROW_SIZE}px solid transparent;
            border-right: ${ARROW_SIZE}px solid transparent;
            border-top: ${ARROW_SIZE}px solid ${_backgroundColor};
          `,
            left: undefined,
            right: undefined
          }[_position]
        }
      }
    `}
`;

const SVG = styled.svg<{ _position?: PopoverPosition }>`
  pointer-events: none;
  position: absolute;

  ${({ _position }) =>
    _position &&
    css`
      ${
        {
          top: css`
          top: 0;
          left: 0;
        `,
          bottom: css`
          bottom: 0;
          left: 0;
        `,
          left: undefined,
          right: undefined
        }[_position]
      }
    `}
`;

const Path = styled.path`
  pointer-events: auto;
  fill: transparent;
`;

/**
 * TODO wrong path when popover is smaller then childRect
 */
const getForgivingHitAreaPath = (
  popoverState: PopoverState,
  tolerance: number
) => {
  const { position, popoverRect, childRect, nudgedLeft, align } = popoverState;
  const fullAreaHeight = childRect.height + popoverRect.height + OFFSET;

  const getPath = (
    childRectTopLeft: number,
    childRectTopRight: number,
    popoverPosition: PopoverState['position']
  ): string | undefined =>
    ({
      top: `
       M 0 ${popoverRect.height}
       S ${childRectTopLeft} ${popoverRect.height + OFFSET + tolerance},
         ${childRectTopLeft} ${fullAreaHeight}
       L ${childRectTopLeft} ${popoverRect.height + OFFSET}
       L ${childRectTopRight} ${popoverRect.height + OFFSET}
       L ${childRectTopRight} ${fullAreaHeight}
       S ${childRectTopRight} ${popoverRect.height + OFFSET + tolerance},
         ${popoverRect.width} ${popoverRect.height}
     `,
      bottom: `
        M 0 ${childRect.height + OFFSET}
        S ${childRectTopLeft} ${childRect.height - tolerance},
          ${childRectTopLeft} 0
        L ${childRectTopLeft} ${childRect.height}
        L ${childRectTopRight} ${childRect.height}
        L ${childRectTopRight} 0
        S ${childRectTopRight} ${childRect.height - tolerance},
          ${popoverRect.width} ${childRect.height + OFFSET}
      `,
      left: undefined,
      right: undefined
    })[popoverPosition ?? 'top'];

  switch (align) {
    case 'start': {
      const childRectTopLeftX = Math.abs(nudgedLeft);
      const childRectTopRightX = childRectTopLeftX + childRect.width;

      return getPath(childRectTopLeftX, childRectTopRightX, position);
    }
    case 'center': {
      const childRectTopLeftX =
        (popoverRect.width - childRect.width) / 2 - nudgedLeft;
      const childRectTopRightX = childRectTopLeftX + childRect.width;

      return getPath(childRectTopLeftX, childRectTopRightX, position);
    }
    case 'end': {
      const childRectTopLeftX =
        popoverRect.width - childRect.width - Math.abs(nudgedLeft);
      const childRectTopRightX = childRectTopLeftX + childRect.width;

      return getPath(childRectTopLeftX, childRectTopRightX, position);
    }
  }

  return undefined;
};
