import { useState } from 'react';
import { IconButton, Popover, Tooltip } from '@elfsight-universe/ui-common';
import Help from '@icons/help.svg';
import { HeaderUserHelpPopover } from './header-user-help-popover';

export function HeaderUserHelp() {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      onClickOutside={() => setPopoverOpen(false)}
      positions={['bottom']}
      align="end"
      content={
        <HeaderUserHelpPopover onRequestClose={() => setPopoverOpen(false)} />
      }
    >
      <Tooltip content="Help">
        <IconButton
          active={isPopoverOpen}
          icon={Help}
          onClick={() => setPopoverOpen(!isPopoverOpen)}
        />
      </Tooltip>
    </Popover>
  );
}
