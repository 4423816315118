import styled from 'styled-components';
import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import { WidgetsLimitFeatureTooltip } from './widgets-limit-feature-tooltip';

export const widgetsLimitFeatureResolver: FeatureResolver = (
  { widgetsLimit, appsCount, appName },
  pricingTableColorTheme
) => {
  function renderText() {
    const widgetOrWidgetsLabel = widgetsLimit > 1 ? 'widgets' : 'widget';
    const isPackPlan = appsCount ?? 1 > 1;

    if (isPackPlan) {
      return (
        <WidgetsLimitFeatureTooltip
          widgetsLimit={widgetsLimit}
          isPackPlan
          pricingTableColorTheme={pricingTableColorTheme}
        >
          <>
            {widgetsLimit} {widgetOrWidgetsLabel} per app
          </>
        </WidgetsLimitFeatureTooltip>
      );
    }

    if (appName) {
      return (
        <WidgetsLimitFeatureTooltip
          widgetsLimit={widgetsLimit}
          appName={appName}
          pricingTableColorTheme={pricingTableColorTheme}
        >
          <>
            {widgetsLimit} {widgetOrWidgetsLabel}
          </>
        </WidgetsLimitFeatureTooltip>
      );
    }

    return (
      <WidgetsLimitFeatureTooltip
        widgetsLimit={widgetsLimit}
        pricingTableColorTheme={pricingTableColorTheme}
      >
        <>
          {widgetsLimit} {widgetOrWidgetsLabel} of the app
        </>
      </WidgetsLimitFeatureTooltip>
    );
  }

  return { text: renderText() };
};

// the problem is being solved here is that even we have a multiline string
// we don't want the tooltip to be wrapped apart the last word
// we can certanly achieve this by using &nbsp; but it's unreliable
// because it could be broken just by changing lines formatting
const NoWrap = styled.span`
  white-space: nowrap;
`;
