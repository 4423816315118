import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { Button, Checkbox, H4, MOBILE } from '@elfsight-universe/ui-common';
import { beacon } from '@modules/beacon';
import { CreateInstallationRequestAppealRequest } from '@elfsight-universe/service-core-contracts/appeal';
import { requestInstallationCollaboratorTutorialsList } from '../constants/request-installation-collaborator-tutorials-list';

export function CollaboratorBlock({ ...forwardingProps }) {
  const {
    register,
    formState: { errors, touchedFields },
    getValues,
    watch,
    setError,
    clearErrors
  } = useFormContext<CreateInstallationRequestAppealRequest>();

  const watchCollaboratorAccess = watch('collaboratorAccess');

  const onShareAccessGuideClick = () => {
    const platformName = getValues('platform');
    const platformTutorial = requestInstallationCollaboratorTutorialsList.find(
      ({ platform }) => platform === platformName
    );
    if (platformTutorial) {
      beacon.article(platformTutorial.articleID);
    }
  };

  const onCollaboratorAccessChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked && touchedFields.collaboratorAccess) {
      setError('collaboratorAccess', {
        type: 'validate',
        message: 'Field required.'
      });
      return;
    }

    clearErrors('collaboratorAccess');
  };

  return (
    <Container _isError={!!errors?.collaboratorAccess} {...forwardingProps}>
      <Header>
        <H4>Please add our developer as a Collaborator on your site.</H4>

        <Button
          type="button"
          variation="accentSecondary"
          small
          onClick={onShareAccessGuideClick}
        >
          Find out how to share access
        </Button>
      </Header>

      <Checkbox
        {...register('collaboratorAccess', {
          onChange: onCollaboratorAccessChange
        })}
        checkedColor="accent"
        checked={watchCollaboratorAccess}
        label="I have added the Collaborator as instructed. *"
      />
    </Container>
  );
}

const Container = styled.div<{ _isError?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid
    ${({ theme, _isError }) =>
      _isError ? theme.colors.alert : theme.colors.gray30};

  ${MOBILE} {
    gap: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;
