import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const CLIP_SIZE = 52;
const CLIP_PATH =
  'M20.511 0H31.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 0 1 6.048 6.048C51.257 10.793 52 13.38 52 20.511V31.49c0 7.132-.743 9.718-2.137 12.326a14.538 14.538 0 0 1-6.048 6.048C41.207 51.257 38.62 52 31.489 52H20.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 0 1-6.048-6.048C.743 41.207 0 38.62 0 31.489V20.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 0 1 6.048-6.048C10.793.743 13.38 0 20.511 0z';

export type SmoothCornersIconProps = PropsWithChildren<{
  color: string;
  size: number;
}>;

export function SmoothCornersIcon({
  color,
  size,
  children,
  ...forwardingProps
}: SmoothCornersIconProps) {
  return (
    <Container _size={size} {...forwardingProps}>
      <SmoothCornersBackground
        style={{ transform: `scale(${size / CLIP_SIZE})` }}
        _color={color}
      />
      {children}
    </Container>
  );
}

const SmoothCornersBackground = styled.div<{ _color: string }>`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: ${CLIP_SIZE}px;
  height: ${CLIP_SIZE}px;
  background: ${({ _color }) => _color};
  border-radius: 16px;
  transform-origin: left top;
  clip-path: path('${CLIP_PATH}');
`;

const Container = styled.div<{ _size: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 ${({ _size }) => _size}px;
  height: ${({ _size }) => _size}px;
  width: ${({ _size }) => _size}px;
`;
